import { Link as LinkMUI, Typography, Breadcrumbs } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { contractGetByCodeOptions } from 'api/oav/ContractQuery.api';
import { Box, Grid, Skeleton } from '@mui/material';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import React from 'react';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import AppBar from 'components/AppBar';
import dayjs from 'dayjs';

const ContractPage = () => {
  const navigate = useNavigate();
  const { code } = useParams();

  if (!code) {
    navigate('/portefeuille');
    return null;
  }

  const { data: contract, error } = useQuery(contractGetByCodeOptions(code));

  document.title = `Contrat - ${contract?.code}`;

  return (
    <>
      <AppBar>
        <Box sx={{ width: '100%' }}>
          <Breadcrumbs
            separator={<ChevronRightIcon size="small" />}
            sx={{ mb: 4 }}
          >
            <Link to="/portefeuille" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" variant="body2" color="inherit">
                Portefeuille
              </LinkMUI>
            </Link>
            <Typography color="text.primary">Contrat</Typography>
          </Breadcrumbs>
        </Box>
      </AppBar>

      {error ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{ pt: 4, width: '100%', height: 200, marginX: 'auto' }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CardError status={(error as RestError).status} />
            </Grid>
          </Grid>
        </Box>
      ) : !contract ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{ pt: 4, width: '100%', height: 200, marginX: 'auto' }}
        >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" animation="wave" height="300px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" animation="wave" height="300px" />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>
          <Typography>Numéro de contrat : {contract.code}</Typography>
          <Typography>
            {`Souscripteur : ${contract.subscriber.civility} ${contract.subscriber.lastName} ${contract.subscriber.firstName}`}
          </Typography>
          <Typography>
            Date d&apos;effet :&nbsp;
            {dayjs(contract.effectiveDate).format('DD MMM YYYY').toLowerCase()}
          </Typography>

          {contract.cancelDate && (
            <Typography color="#F44336" fontWeight="bold">
              Ce contrat a été résilié le:&nbsp;
              {dayjs(contract.cancelDate).format('DD MMMM YYYY').toLowerCase()}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default ContractPage;
