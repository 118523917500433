import { useAuth } from 'react-oidc-context';
import { ApiResponse } from 'models/api.model';
import { RestError } from 'errors/RestError';
import { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import dayjs from 'dayjs';

export const useAccessToken = (): string => {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;

  if (!accessToken) {
    throw new Error(
      'Fetch could not be performed, secured endpoint requested without being authenticated.',
    );
  }
  return accessToken;
};

export const isBadRequestWithDetailsError = <T>(
  error: RestError<ApiResponse<T>> | Error,
): error is RestError<ApiResponse<T>> => {
  return (
    (error as RestError).status === 400 &&
    !!(error as RestError<ApiResponse<T>>).response?.details
  );
};

export const handleMutationError = <RequestError>(
  error: RestError<ApiResponse<RequestError>> | Error,
  handle: {
    onBadRequest: (error: RequestError) => void;
    handleError: (error: RestError<ApiResponse<RequestError>> | Error) => void;
  },
) => {
  if (
    isBadRequestWithDetailsError<RequestError>(error) &&
    error.response?.details
  ) {
    handle.onBadRequest(error.response?.details);
  } else {
    handle.handleError(error);
  }
};

export type MutationGenerator<TData, TVariables> = (
  mutationOptions: UseMutationOptions<TData, Error | RestError, TVariables>,
) => UseMutationResult<TData, Error | RestError, TVariables>;

export const formatDate = (date?: Date | null): string | undefined => {
  return date ? dayjs(date).format('YYYY-MM-DD') : undefined;
};

export const iterateListErrors = (
  values: any,
  listKey: string,
  callback: (p: [key: string, error: any]) => void,
) => {
  Object.entries(values)
    .filter(([key]) => key.startsWith(`${listKey}[`))
    .forEach(([key, value]) => {
      const prefixPath = key.replace('[', '.').replace(']', '');
      Object.entries(value as Record<string, never>).forEach(([k, v]) =>
        callback([`${prefixPath}.${k}`, v]),
      );
    });
};
