import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

const DatePickerActionBar = (props: PickersActionBarProps) => {
  const { onAccept, onClear, onCancel, onSetToday, actions, className } = props;

  const actionItems = actions?.map(actionType => {
    switch (actionType) {
      case 'clear':
        return (
          <Button
            onClick={() => {
              onClear();
            }}
            key={actionType}
          >
            Annuler
          </Button>
        );
      case 'cancel':
        return (
          <Button
            variant="text"
            color="default"
            onClick={() => {
              onCancel();
            }}
            key={actionType}
          >
            Annuler
          </Button>
        );
      case 'accept':
        return (
          <Button
            onClick={() => {
              onAccept();
            }}
            key={actionType}
          >
            Valider
          </Button>
        );
      case 'today':
        return (
          <Button
            onClick={() => {
              onSetToday();
            }}
            key={actionType}
          >
            Aujourd'hui
          </Button>
        );
      default:
        return null;
    }
  });

  if (actions == null || actions.length === 0) {
    return null;
  }

  return <DialogActions className={className}>{actionItems}</DialogActions>;
};

export default DatePickerActionBar;
