import React, { useMemo, useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';

import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import IdentityForm from 'content/form/PersonForm/IdentityForm';
import ContactInfoForm from 'content/form/PersonForm/ContactInfoForm';
import { PersonFormValues } from 'models/Form.model';
import { Person, PersonRequestPutErrors } from 'models/oav/Person.models';
import {
  personFormValuesToRequest,
  personToInitialFormValues,
  personValidationSchema,
} from '../personForm';
import { usePutPersonMutation } from 'api/oav/PersonQuery.api';
import { useAppContext } from 'components/Context/AppContext';
import { isBadRequestWithDetailsError } from 'utils/api/api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';

interface PersonUpdateProps {
  person: Person;
  onBackToViewMode: () => void;
}

const Buttons = (props: { onCancel: () => void; isPending: boolean }) => (
  <Grid item xs={12}>
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      useFlexGap
      spacing={2}
      justifyContent="flex-end"
      sx={{ mt: 2 }}
    >
      <Button
        color="default"
        size="medium"
        onClick={props.onCancel}
        sx={{ px: 2 }}
        disabled={props.isPending}
      >
        Annuler
      </Button>
      <Button
        size="medium"
        type="submit"
        sx={{ px: 2 }}
        disabled={props.isPending}
      >
        {props.isPending ? (
          <CircularProgress color="inherit" size={28} />
        ) : (
          'Enregistrer'
        )}
      </Button>
    </Stack>
  </Grid>
);

export const PersonUpdate = (props: PersonUpdateProps) => {
  const { addSnackbar } = useAppContext();
  const formikRef = useRef<FormikProps<PersonFormValues>>(null);

  const handleError = (error: Error) => {
    console.error(error);
    if (
      isBadRequestWithDetailsError<PersonRequestPutErrors>(error) &&
      error.response?.details
    ) {
      onBadRequest(error.response.details);
    } else {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    }
  };

  const onBadRequest = (errorDetails: PersonRequestPutErrors) => {
    const addressErrors = errorDetails.address;
    const errors = { ...errorDetails, address: undefined };
    Object.entries(errors).forEach(([key, value]) => {
      formikRef.current?.setFieldError(key, value);
      formikRef.current?.setFieldTouched(key, true, false);
    });
    if (addressErrors) {
      Object.entries(addressErrors).forEach(([key, value]) => {
        formikRef.current?.setFieldError(`address.${key}`, value);
        formikRef.current?.setFieldTouched(`address.${key}`, true, false);
      });
    }
  };

  const mutation = usePutPersonMutation(props.onBackToViewMode, handleError);

  const handleSubmit = (values: PersonFormValues) => {
    if (!mutation.isPending) {
      const personRequest = personFormValuesToRequest(values);
      mutation.mutate({ person: personRequest, uuid: props.person.id! });
    }
  };

  const initialValues = useMemo(
    () => personToInitialFormValues(props.person),
    [],
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={personValidationSchema}
      validateOnMount={true}
    >
      {() => (
        <Form>
          <Box
            display="flex"
            justifyContent="center"
            sx={{ pt: 4, width: '100%', maxWidth: 1200, marginX: 'auto' }}
          >
            <Stack direction="column" justifyContent="center" spacing={2}>
              <IdentityForm />
              <ContactInfoForm />
              <Buttons
                isPending={mutation.isPending}
                onCancel={props.onBackToViewMode}
              />
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
