import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import { MenuIcon } from 'components/Icon/MenuIcon';
import { CloseIcon } from 'components/Icon/CloseIcon';
import SidebarContent from 'components/Sidebar/content';
import { TransitionSlideUP } from 'utils/animations/transitions';

export type AppBarProps = {
  children: ReactElement;
};

const AppBar = (props: AppBarProps) => {
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  const [showMenu, setShowMenu] = useState(false);

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={1}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        {props.children}
      </Box>

      <Button
        variant="text"
        onClick={() => setShowMenu(true)}
        sx={{
          display: {
            sm: 'none',
            xs: 'flex',
          },
          minWidth: 'auto',
          borderRadius: 3,
          p: 1,
          backgroundColor: alpha('#000000', 0.1),
          ':hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
          },
        }}
      >
        <MenuIcon color={theme.palette.text.primary} size="small" />
      </Button>

      <Dialog
        fullWidth
        fullScreen
        TransitionComponent={TransitionSlideUP}
        open={showMenu}
      >
        <DialogTitle
          sx={{
            p: 1.5,
          }}
        >
          <Stack
            direction="row-reverse"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              onClick={() => setShowMenu(false)}
              sx={{
                minWidth: 'auto',
                borderRadius: 3,
                p: 1,
                backgroundColor: alpha('#000000', 0.1),
                ':hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.15),
                },
              }}
            >
              <CloseIcon color={theme.palette.text.primary} size="small" />
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0.5,
          }}
        >
          <SidebarContent />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default AppBar;
