import { useTheme } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';

import {
  ProjectStatus,
  projectStatusData,
} from 'models/oav/ProjectStatus.models';

export const ChipProjectStatus = (
  props: { projectStatus: ProjectStatus } & Pick<ChipProps, 'sx'>,
) => {
  const theme = useTheme();

  return (
    <Chip
      label={projectStatusData[props.projectStatus].label}
      color="default"
      variant="outlined"
      size="medium"
      sx={{
        fontWeight: 'bold',
        borderColor: projectStatusData[props.projectStatus].color,
        color: theme.palette.text.primary,
        ...props.sx,
      }}
      icon={
        <span
          style={{
            color: projectStatusData[props.projectStatus].color,
            marginLeft: 8,
          }}
        >
          ⬤
        </span>
      }
    />
  );
};
