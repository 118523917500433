import { WrongStatusError } from 'api/referentiels';
import { PageResponse, Pagination } from 'models/Page.models';
import {
  Commission,
  CommissionSearchCriteriaRequest,
} from 'models/oav/Commission.models';
import { addParams, buildParams } from 'utils/api/params';
import { formatDate } from 'utils/api/api';

const url = `${process.env.REACT_APP_API_URI_BASE}/oav/commission`;

export const fetchCommissionList = async (
  pagination: Pagination,
  criteria: CommissionSearchCriteriaRequest,
  accessToken: string,
  sort?: string[],
): Promise<PageResponse<Commission>> => {
  const params = new URLSearchParams();

  buildParams(pagination, 'pagination')?.forEach(([key, value]) =>
    params.append(key, value),
  );

  const formattedCriteria = {
    ...criteria,
    startPaymentDate: formatDate(criteria.startPaymentDate),
    endPaymentDate: formatDate(criteria.endPaymentDate),
  };
  addParams(params, formattedCriteria, 'criterias');

  if (sort) params.append('sort', sort.join(','));
  const response = await fetch(`${url}?${params}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }
  return await response.json();
};

export const getCsvCommissionList = async (
  criteria: CommissionSearchCriteriaRequest,
  accessToken: string,
) => {
  const params = new URLSearchParams();

  const formattedCriteria = {
    ...criteria,
    startPaymentDate: formatDate(criteria.startPaymentDate),
    endPaymentDate: formatDate(criteria.endPaymentDate),
  };
  addParams(params, formattedCriteria);

  return fetch(`${url}/csv?${params}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
