import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PriceNumber from 'content/formula/PriceNumber';
import { Formula } from 'models/referentiels/Formula.model';
import { TrfPricing } from 'models/referentiels/Tarification.models';
import { Offer } from 'models/referentiels/Offer.model';

export type FormulaBoxProps = {
  offer?: Offer;
  formula: Formula;
  pricing?: TrfPricing;
};

const FormulaBox = (props: FormulaBoxProps) => {
  const theme = useTheme();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          sx={{
            color: props.formula.color || theme.palette.text.primary,
            textOverflow: 'ellipsis',
          }}
          noWrap
          fontWeight={700}
        >
          {props.formula.label}
        </Typography>
      </Grid>

      {props.formula.description && (
        <Grid item xs={12}>
          <Typography variant="caption">{props.formula.description}</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography variant="caption" noWrap>
            par mois
          </Typography>
          <Typography variant="body1" noWrap>
            <PriceNumber
              price={props.pricing?.perMonth}
              currency={props.pricing?.currency}
            />
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography variant="caption" noWrap>
            par an
          </Typography>
          <Typography variant="body1" noWrap>
            <PriceNumber
              price={props.pricing?.perYear}
              currency={props.pricing?.currency}
            />
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

FormulaBox.displayName = 'FormulaBox';

export default FormulaBox;
