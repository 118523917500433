import { memo, useMemo } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
  WarrantyAcronymMap,
  WarrantySection,
} from 'models/referentiels/Warranty.model';
import { Formula } from 'models/referentiels/Formula.model';
import { WarrantySubSectionTableRows } from 'content/warranty/WarrantySubSectionTableRows';

export type WarrantySectionTableRowsProps = {
  section: WarrantySection;
  formulas: Formula[];
  formulasSelected?: string[];
  labelOnLeftColumn?: boolean;
  onlyFormulas?: string[];
  acronymsMap?: WarrantyAcronymMap;
};

const WarrantySectionTableRows = memo(
  (props: WarrantySectionTableRowsProps) => {
    const formulas = useMemo(() => {
      if (props.onlyFormulas)
        return props.formulas.filter(_ => props.onlyFormulas?.includes(_.code));

      return props.formulas;
    }, [props.formulas, props.onlyFormulas]);

    const nbFormula = useMemo(() => formulas.length, [formulas]);

    const subSections = useMemo(
      () => props.section.subSections.sort((a, b) => a.order - b.order),
      [props.section.subSections],
    );

    return (
      <>
        {props.section.comment && (
          <TableRow>
            <TableCell colSpan={nbFormula + (props.labelOnLeftColumn ? 2 : 0)}>
              <Typography variant="caption">{props.section.comment}</Typography>
            </TableCell>
          </TableRow>
        )}

        {subSections.map((subSection, subSectionIdx) => {
          return (
            <WarrantySubSectionTableRows
              key={subSectionIdx}
              subSection={subSection}
              nbFormula={nbFormula}
              formulas={formulas}
              formulasSelected={props.formulasSelected}
              labelOnLeftColumn={props.labelOnLeftColumn}
              acronymsMap={props.acronymsMap}
            />
          );
        })}
      </>
    );
  },
);

WarrantySectionTableRows.displayName = 'WarrantySectionTableRows';

export default WarrantySectionTableRows;
