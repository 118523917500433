import { Person, PersonRequest } from './Person.models';

export enum BeneficiaryType {
  SUBSCRIBER = 'SUBSCRIBER',
  PARTNER = 'PARTNER',
  CHILD = 'CHILD',
}

export const enumBeneficiaryTypes = [
  {
    value: BeneficiaryType.SUBSCRIBER,
    label: 'Adhérent',
  },
  {
    value: BeneficiaryType.PARTNER,
    label: 'Conjoint',
  },
  {
    value: BeneficiaryType.CHILD,
    label: 'Enfant',
  },
];

export const beneficiaryLabels: {
  [key in BeneficiaryType]: { label: string };
} = {
  [BeneficiaryType.SUBSCRIBER]: {
    label: 'Adhérent principal',
  },
  [BeneficiaryType.PARTNER]: {
    label: 'Conjoint',
  },
  [BeneficiaryType.CHILD]: {
    label: 'Enfant',
  },
};

export type Beneficiary = {
  id?: string;
  techDateCreated?: Date;
  techDateModification?: Date;
  type: BeneficiaryType;
  person: Person;
  remoteTransmission?: boolean;
};

export type BeneficiaryRequestPost = {
  beneficiaryId?: string;
  type: BeneficiaryType;
  personId?: string;
  birthdate?: Date;
  regimeCode?: string;
  postalCode?: string;
};

export type BeneficiaryRequestPostErrors = {
  beneficiaryId?: string;
  type?: string;
  personId?: string;
  birthdate?: string;
  regimeCode?: string;
  postalCode?: string;
};

export type UpdateBeneficiariesRequest = {
  beneficiaries: UpdateBeneficiaryRequest[];
};

export type UpdateBeneficiaryRequest = {
  id: string;
  birthOrder?: number;
  nni?: string;
  remoteTransmission?: boolean;
  cpamId?: string;
};

export type UpdateBeneficiariesRequestErrors = {
  [key: `beneficiaries[${number}]`]: UpdateBeneficiaryRequestErrors | undefined;
};

export type UpdateBeneficiaryRequestErrors = {
  id?: string;
  birthOrder?: string;
  nni?: string;
  remoteTransmission?: string;
  cpamId?: string;
};
