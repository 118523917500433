import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import AppBar from 'components/AppBar';
import { Formik } from 'formik';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ButtonNewProject } from 'components/Button/ButtonNewProject/ButtonNewProject';
import { ProjectFilter, validationSchema } from 'pages/project/ProjectFilter';
import { ProjectTable } from 'pages/project/ProjectTable';
import { InputAdornment, TextField } from '@mui/material';
import { SearchIcon } from 'components/Icon/SearchIcon';
import { useTheme } from '@mui/material/styles';

const ProjectsPage: React.FC = () => {
  document.title = `Projets - ${appName}`;

  const theme = useTheme();

  const [total, setTotal] = useState(0);

  return (
    <AppBar>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Formik
          initialValues={{
            offers: [],
            segments: [],
            phases: [],
            statuses: [],
            toDate: null,
            fromDate: null,
            search: '',
          }}
          onSubmit={() => {}}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({ values, handleChange, touched, handleBlur, errors }) => (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                flexWrap="wrap"
                useFlexGap
                spacing={1}
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="h2">Projets</Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  name="search"
                  value={values.search}
                  error={touched.search && !!errors.search}
                  helperText={touched.search && errors.search}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Recherche"
                  sx={{
                    maxWidth: '500px',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color={theme.palette.text.secondary} />
                      </InputAdornment>
                    ),
                  }}
                />
                <ButtonNewProject />
              </Stack>
              <Stack>
                <Box sx={{ marginTop: 7, marginBottom: 5 }}>
                  <ProjectFilter nbProject={total} />
                </Box>
                <ProjectTable onContractFetched={setTotal} />
              </Stack>
            </>
          )}
        </Formik>
      </Box>
    </AppBar>
  );
};

export default ProjectsPage;
