import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { TableLinearLoading } from 'components/Table/TableLinearLoading';
import { TablePagination } from 'components/Table/TablePagination';
import React, { ChangeEvent, useState } from 'react';
import { LoadingTableRows } from 'components/Table/LoadingTableRows';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { commissionQueries } from 'api/oav/CommissionQuery.api';
import { Pagination } from 'models/Page.models';
import { CommissionSearchCriteriaRequest } from 'models/oav/Commission.models';
import { FormikObserver } from 'utils/formik/FormikObserver';
import { WalletCommissionsFormData } from 'pages/wallet/commissions/WalletCommissionsFilter';
import { ErrorMessage } from 'components/Message/ErrorMessage';

interface Sort {
  by: string;
  order: '+' | '-';
}

export const WalletCommissionsTable = () => {
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
  });

  const [sort, setSort] = useState<Sort>({
    by: 'paymentDate',
    order: '-',
  });

  const [commissionSearchCriteriaRequest, setCommissionSearchCriteriaRequest] =
    useState<CommissionSearchCriteriaRequest>({
      inRisks: [],
      inSegments: [],
      startPaymentDate: null,
      endPaymentDate: null,
    });

  const onFilterChange = (searchCriteria: WalletCommissionsFormData) => {
    setCommissionSearchCriteriaRequest({
      inRisks: searchCriteria.risks.map(r => r.label),
      inSegments: searchCriteria.segments.map(s => s.label),
      startPaymentDate:
        searchCriteria.startPaymentDate &&
        searchCriteria.startPaymentDate.isValid()
          ? searchCriteria.startPaymentDate.toDate()
          : null,
      endPaymentDate:
        searchCriteria.endPaymentDate && searchCriteria.endPaymentDate.isValid()
          ? searchCriteria.endPaymentDate.toDate()
          : null,
    });

    setPagination(p => ({ ...p, page: 0 }));
  };

  const { data, error, isFetching } = useQuery(
    commissionQueries.searchCommission(
      pagination,
      commissionSearchCriteriaRequest,
      [sort.order + sort.by],
    ),
  );
  const loading = data === undefined;

  const handleSort = (idColumn: string) => {
    if (sort.by == idColumn) {
      setSort({ ...sort, order: sort.order === '+' ? '-' : '+' });
    } else {
      setSort({ by: idColumn, order: '+' });
    }
  };

  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination(() => ({ page: 0, size: parseInt(e.target.value) }));
  };
  const handlePageChange = (_: unknown, newPage: number) => {
    setPagination(p => ({ ...p, page: newPage }));
  };

  return (
    <TableContainer sx={{ width: '100%' }}>
      <FormikObserver onChange={onFilterChange} />
      {error ? (
        <ErrorMessage message="Une erreur est survenu lors de la récupération des commissions." />
      ) : (
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow sx={{ position: 'relative' }}>
              <TableCell>
                {isFetching && <TableLinearLoading />}
                <TableSortLabel
                  active={sort.by === 'paymentDate'}
                  direction={
                    sort.order === '+' || sort.by !== 'paymentDate'
                      ? 'asc'
                      : 'desc'
                  }
                  onClick={() => handleSort('paymentDate')}
                  sx={{ '.MuiTableSortLabel-icon': { opacity: 0.3 } }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <TableSortLabel
                  active={sort.by === 'policyStartDate'}
                  direction={
                    sort.order === '+' || sort.by !== 'policyStartDate'
                      ? 'asc'
                      : 'desc'
                  }
                  sx={{ '.MuiTableSortLabel-icon': { opacity: 0.3 } }}
                  onClick={() => handleSort('policyStartDate')}
                >
                  Période
                </TableSortLabel>
              </TableCell>
              <TableCell>Risque</TableCell>
              <TableCell>Segment</TableCell>
              <TableCell>Offre</TableCell>
              <TableCell>Contrat</TableCell>
              <TableCell>Identifiant</TableCell>
              <TableCell>Souscripteur</TableCell>
              <TableCell sx={{ textWrap: 'nowrap' }}>Cotisation</TableCell>
              <TableCell>Taux</TableCell>
              <TableCell sx={{ textWrap: 'nowrap' }}>Commissions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <LoadingTableRows rowSize={pagination.size!} cellSize={11} />
            ) : data?.totalElements != 0 ? (
              data?.content.map((commission, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {commission.paymentDate
                        ? dayjs(commission.paymentDate).format('DD MMM YYYY')
                        : undefined}
                    </TableCell>
                    <TableCell sx={{ textWrap: 'nowrap' }}>
                      Du
                      {commission.policyStartDate
                        ? ' ' +
                          dayjs(commission.policyStartDate).format(
                            'DD MMM YYYY',
                          ) +
                          ' '
                        : undefined}
                      au
                      {commission.policyEndDate
                        ? ' ' +
                          dayjs(commission.policyEndDate).format('DD MMM YYYY')
                        : undefined}
                    </TableCell>
                    <TableCell>
                      {commission.riskLabel ? commission.riskLabel : undefined}
                    </TableCell>
                    <TableCell>
                      {commission.segmentLabel
                        ? commission.segmentLabel
                        : undefined}
                    </TableCell>
                    <TableCell sx={{ textWrap: 'nowrap' }}>
                      {commission.offerLabel
                        ? commission.offerLabel
                        : undefined}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {commission.contractCode
                        ? commission.contractCode
                        : undefined}
                    </TableCell>
                    <TableCell>
                      {commission.subscriberCode
                        ? commission.subscriberCode
                        : undefined}
                    </TableCell>
                    <TableCell
                      sx={{
                        textWrap: 'nowrap',
                      }}
                    >
                      {commission.subscriberCivility
                        ? commission.subscriberCivility + ' '
                        : undefined}

                      {commission.subscriberLastname
                        ? commission.subscriberLastname + ' '
                        : undefined}

                      {commission.subscriberFirstname
                        ? commission.subscriberFirstname
                        : undefined}
                    </TableCell>
                    <TableCell>
                      {commission.contribution
                        ? commission.contribution
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')
                        : undefined}
                      €
                    </TableCell>
                    <TableCell>
                      {commission.commissionRate
                        ? commission.commissionRate
                        : undefined}
                      %
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {commission.commission
                        ? commission.commission
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')
                        : undefined}
                      €
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={11}
                  sx={{ opacity: 0.8, textAlign: 'center' }}
                >
                  Aucune commission trouvée
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={12}
                rowsPerPageOptions={[10, 25, 50]}
                count={data?.totalElements ?? 0}
                rowsPerPage={pagination.size!}
                page={pagination.page!}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                sx={{
                  '.MuiTablePagination-spacer': { display: 'none' },
                  '.MuiTablePagination-toolbar': {
                    margin: 'auto',
                    width: 'max-content',
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </TableContainer>
  );
};
