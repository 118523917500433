import { ReactNode, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';

import AutocompleteBox, {
  AutocompleteBoxProps,
} from '../../Autocomplete/AutocompleteBox';
import { ChevronRightIcon } from '../../Icon/ChevronRightIcon';
import Paper from '@mui/material/Paper';

export type SelectFilterProps = AutocompleteBoxProps & {
  button?: Partial<ButtonProps>;
  showCount?: boolean;
  children: ReactNode;
};

const SelectFilter = (props: SelectFilterProps) => {
  const theme = useTheme();

  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);
  const filterOpened = Boolean(anchorFilter);

  const openFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilter(event.currentTarget);
  };

  const closeFilter = () => {
    if (anchorFilter) {
      anchorFilter.focus();
    }
    setAnchorFilter(null);
  };

  return (
    <Box>
      <Button
        {...props.button}
        variant={props.button?.variant || 'outlined'}
        color={props.button?.color || 'default'}
        sx={{
          py: 1,
        }}
        onClick={openFilter}
        startIcon={
          props.showCount &&
          props.value?.length > 0 && (
            <Chip
              label={props.value.length}
              size="small"
              sx={{
                ml: 1,
                fontWeight: 700,
                fontSize: `${theme.typography.caption.fontSize} !important`,
                borderRadius: 2,
              }}
            />
          )
        }
        endIcon={
          <ChevronRightIcon
            size="small"
            color={theme.palette.text.disabled}
            style={{ transform: 'rotate(90deg)' }}
          />
        }
      >
        {props.children}
      </Button>
      <Popper
        open={filterOpened}
        anchorEl={anchorFilter}
        placement="bottom-start"
        sx={{
          minWidth: {
            sm: 260,
            xs: 'none',
          },
        }}
      >
        <ClickAwayListener onClickAway={closeFilter}>
          <Paper
            sx={{
              background: theme.palette.background.paper,
              p: 1,
              borderRadius: 2,
            }}
          >
            <AutocompleteBox {...props} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default SelectFilter;
