import { SignatureStatus } from 'models/oav/CommercialProposition.models';

export type ContractualDocument = {
  id: string;
  filename: string;
  mimeType: string;
  type: ContractualDocumentType;
  signatureStatus: SignatureStatus;
};

export type ContractualDocumentDownload = ContractualDocument & {
  downloadUrl: string;
};

export enum ContractualDocumentType {
  FIRST_PAGE = 'FIRST_PAGE',
  CUSTOM_STUDY = 'CUSTOM_STUDY',
  PROPOSITION_INSURANCE = 'PROPOSITION_INSURANCE',
  WARRANTIES_TABLE = 'WARRANTIES_TABLE',
  OPTIONAL_WARRANTY = 'OPTIONAL_WARRANTY',
  SUBSCRIPTION_FORM = 'SUBSCRIPTION_FORM',
  MANDAT_SEPA = 'MANDAT_SEPA',
  MANDAT_CANCELATION = 'MANDAT_CANCELATION',
  MEMBERSHIP_CERTIFICATE = 'MEMBERSHIP_CERTIFICATE',
}
