import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import { LoadingTableRows } from 'components/Table/LoadingTableRows';
import { getFirstStep, projectStepsData } from 'models/oav/ProjectStep.models';
import { TableRowLink } from 'components/Table/TableRowLink';
import Stack from '@mui/material/Stack';
import {
  ProjectStatus,
  projectStatusData,
} from 'models/oav/ProjectStatus.models';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { projectPhasesData } from 'models/oav/ProjectPhase.models';
import { ChipProjectStatus } from 'components/Chip/ChipProjectStatus';
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ArrowCircleRightIcon } from 'components/Icon/ArrowCircleRightIcon';
import { getSegmentLabelsBySubscriptions } from 'utils/segment/label';
import { Link } from 'react-router-dom';

const nbProject = 5;
const noValueLabel = 'Non renseigné';

export const TableLastProject = () => {
  const theme = useTheme();

  const { data, error, isFetching } = useQuery(
    projectQueries.getPage({ page: 0, size: nbProject }, undefined, [
      '-techDateModification',
    ]),
  );

  const { data: offerList } = useQuery(offerQueries.getAll());

  const offerMap = useMemo(
    () =>
      offerList
        ? new Map(offerList.map(offer => [offer.code, offer]))
        : undefined,
    [offerList],
  );

  return (
    <Stack
      sx={{
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#6B7793',
        background: theme.palette.background.default,
        borderRadius: '32px',
        paddingY: '24px',
      }}
    >
      <Stack
        sx={{ width: '100%', paddingX: '24px', marginBottom: '24px' }}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="h3">Derniers Projets</Typography>
        <Link to="/projets">
          <Stack direction="row" sx={{ color: theme.palette.text.secondary }}>
            <Box sx={{ fontWeight: 'bold', marginRight: 1 }}>Voir tous</Box>
            <ArrowCircleRightIcon></ArrowCircleRightIcon>
          </Stack>
        </Link>
      </Stack>
      <TableContainer sx={{ width: '100%' }}>
        {error ? (
          <Box sx={{ px: '24px' }}>
            <ErrorMessage message="Une erreur est survenu lors de la récupération des projets." />
          </Box>
        ) : (
          <Table sx={{ width: '100%' }}>
            <TableBody>
              {isFetching ? (
                <LoadingTableRows rowSize={nbProject} cellSize={6} />
              ) : data?.totalElements != 0 ? (
                data?.content.map((project, idx) => {
                  const to = `/projets/${project.id}`;
                  return (
                    <TableRowLink
                      to={to}
                      key={`entry-${idx}`}
                      sx={{ marginBottom: 2 }}
                    >
                      <TableCell
                        sx={{
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Stack
                          sx={{
                            paddingLeft: '1rem',
                            borderRightColor:
                              projectStatusData[
                                project.status || ProjectStatus.IN_PROGRESS
                              ].color,
                            borderRightWidth: 3,
                            borderRightStyle: 'solid',
                          }}
                        >
                          <Box sx={{ fontWeight: 'bold' }}>
                            {dayjs(project.techDateModification).format(
                              'DD MMM YYYY',
                            )}
                          </Box>
                          <Box>
                            {dayjs(project.techDateModification).format(
                              'HH:mm',
                            )}
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        {getSegmentLabelsBySubscriptions(
                          project.subscriptions,
                          offerMap,
                          noValueLabel,
                        )}
                      </TableCell>
                      <TableCell>
                        {project.subscriber.person.civility
                          ? project.subscriber.person.civility +
                            ' ' +
                            project.subscriber.person.lastname +
                            ' ' +
                            project.subscriber.person.firstname +
                            ' '
                          : noValueLabel}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        {project.currentStep &&
                        projectStepsData[project.currentStep] != null
                          ? projectPhasesData[
                              projectStepsData[project.currentStep]!.phase
                            ].label
                          : noValueLabel}
                      </TableCell>
                      <TableCell
                        sx={{
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {project.status && (
                          <ChipProjectStatus projectStatus={project.status} />
                        )}
                      </TableCell>
                    </TableRowLink>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{ opacity: 0.8, textAlign: 'center' }}
                  >
                    Aucun projet trouvé
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Stack>
  );
};
