import React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';

import { Project } from 'models/oav/Project.models';

export type ButtonBeneficiariesProps = {
  beneficiariesLength: number;
  project: Project;
} & Partial<ButtonProps>;

export const ButtonBeneficiaries: React.FC<ButtonBeneficiariesProps> = ({
  beneficiariesLength,
  project,
  ...buttonProps
}) => {
  return (
    <Button
      color="dark"
      {...buttonProps}
      sx={{
        justifyContent: 'space-between',
        ...buttonProps.sx,
      }}
      endIcon={
        <InputAdornment position="end">
          <Chip
            label={`${beneficiariesLength} /  ${1 + (project.partner ? 1 : 0) + (project.children?.length || 0)}`}
          />
        </InputAdornment>
      }
    >
      Bénéficiaires
    </Button>
  );
};
