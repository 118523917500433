import React, { FC } from 'react';

import dayjs from 'dayjs';
import { FormikProps, getIn } from 'formik';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormControlLabel, Switch } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import {
  BeneficiaryValues,
  CPAMFormikValues,
} from 'content/project/supportingDocument/DialogCPAM';
import { getRegime } from 'utils/fields/fields';
import { NNITextField } from 'components/TextField/formatted/NNITextField';
import TextField from 'components/TextField/TextField';
import {
  birthOrderMax,
  birthOrderMin,
  cpamIdSize,
} from 'utils/validation/yupPerson';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';
import {
  beneficiaryLabels,
  BeneficiaryType,
} from 'models/oav/Beneficiary.models';

interface FormCPAMBeneficiaryProps {
  name: string;
  formikProps: FormikProps<CPAMFormikValues>;
  values: BeneficiaryValues;
  partnerAttachable: boolean;
}

const FormCPAMBeneficiary: FC<FormCPAMBeneficiaryProps> = ({
  name,
  formikProps,
  values,
  partnerAttachable,
}) => {
  const theme = useTheme();

  const { handleChange, handleBlur, setFieldValue } = formikProps;
  const regime = getRegime(values.beneficiaryContext.person.regimeCode);

  const errors = getIn(formikProps.errors, name);
  const touched = getIn(formikProps.touched, name);
  const nniName = `${name}.nni`;
  const cpamIdName = `${name}.cpamId`;
  const birthOrderName = `${name}.birthOrder`;

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          p: 2,
          border: `2px solid ${theme.palette.divider}`,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="flex-start" gap={0.5}>
              <Typography variant="body1">
                {values.beneficiaryContext.person.civility}{' '}
                {values.beneficiaryContext.person.firstname}{' '}
                {values.beneficiaryContext.person.lastname}
              </Typography>
              <Typography variant="body2">
                Né(e) le :{' '}
                {dayjs(values.beneficiaryContext.person.birthdate).format(
                  'DD/MM/YYYY',
                )}
              </Typography>
              <Typography variant="body2">{regime && regime.label}</Typography>
            </Stack>
          </Grid>
          <Grid item container spacing={2} xs={8}>
            {values.canBeAttached && (
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  labelPlacement="start"
                  label="Rattaché(e) à"
                  control={
                    <ToggleButtonGroupSplit
                      sx={{ ml: 2, flexWrap: 'wrap' }}
                      value={values.attachment}
                      exclusive
                      onChange={(e, v) =>
                        setFieldValue(`${name}.attachment`, v)
                      }
                    >
                      <ToggleButtonSplit
                        value={BeneficiaryType.SUBSCRIBER}
                        color="primary"
                        sx={{ minWidth: '190px' }}
                      >
                        {beneficiaryLabels[BeneficiaryType.SUBSCRIBER].label}
                      </ToggleButtonSplit>
                      {partnerAttachable && (
                        <ToggleButtonSplit
                          value={BeneficiaryType.PARTNER}
                          color="primary"
                        >
                          {beneficiaryLabels[BeneficiaryType.PARTNER].label}
                        </ToggleButtonSplit>
                      )}
                      <ToggleButtonSplit value="OTHER" color="primary">
                        Autre
                      </ToggleButtonSplit>
                    </ToggleButtonGroupSplit>
                  }
                />

                {touched?.attachment && errors?.attachment && (
                  <FormHelperText error>{errors?.attachment}</FormHelperText>
                )}
              </Grid>
            )}
            {(!values.canBeAttached || values.attachment === 'OTHER') && (
              <>
                <Grid item xs={7}>
                  <NNITextField
                    required
                    fullWidth
                    id={nniName}
                    name={nniName}
                    label="N° Sécurité sociale"
                    value={values.nni}
                    error={touched?.nni && !!errors?.nni}
                    helperText={touched?.nni && errors?.nni}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    required
                    fullWidth
                    id={cpamIdName}
                    name={cpamIdName}
                    label="Code caisse"
                    value={values.cpamId}
                    error={touched?.cpamId && !!errors?.cpamId}
                    helperText={touched?.cpamId && errors?.cpamId}
                    onChange={e =>
                      setFieldValue(
                        cpamIdName,
                        e.target.value.replaceAll(/\D/g, ''),
                      )
                    }
                    onBlur={handleBlur}
                    inputProps={{
                      minLength: cpamIdSize,
                      maxLength: cpamIdSize,
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={7}>
              <TextField
                fullWidth
                required
                id={birthOrderName}
                name={birthOrderName}
                label="Rang de naissance"
                type="number"
                value={values.birthOrder}
                error={touched?.birthOrder && !!errors?.birthOrder}
                helperText={touched?.birthOrder && errors?.birthOrder}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  min: birthOrderMin,
                  max: birthOrderMax,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Stack
                alignItems="flex-start"
                justifyContent="center"
                sx={{ width: '100%', height: '100%' }}
              >
                <FormControlLabel
                  onChange={(e, checked) =>
                    setFieldValue(`${name}.remoteTransmission`, checked)
                  }
                  sx={{ marginX: 0 }}
                  checked={values.remoteTransmission}
                  control={<Switch />}
                  label="Télétransmission"
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default FormCPAMBeneficiary;
