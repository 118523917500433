import React, {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import {
  NeededSupportingDocument,
  SupportingDocument,
} from 'models/oav/SupportingDocument.models';
import ButtonClose from 'components/Button/ButtonClose';
import { useUploadSupportingDocuments } from 'utils/project/supportingDocuments';
import PreviewDocument from 'content/project/supportingDocument/PreviewDocument';
import Card from '@mui/material/Card';
import { Form, Formik, FormikProps } from 'formik';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';
import { FormControlLabel, Switch } from '@mui/material';
import FormBankDetails from 'content/project/supportingDocument/FormBankDetails';
import DropzoneBankDetails from 'content/project/supportingDocument/DropzoneBankDetails';
import { Project } from 'models/oav/Project.models';
import {
  BankDetailsRequestErrors,
  PaymentDay,
  PaymentFrequency,
  paymentFrequencyLabels,
  Subscription,
} from 'models/oav/Subscription.models';
import { mutations } from 'api/oav/SubscriptionQuery.api';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppContext } from 'components/Context/AppContext';
import { isBadRequestWithDetailsError } from 'utils/api/api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { setFormikError } from 'utils/fields/fields';
import { messageFieldRequired } from 'utils/validation/message';
import {
  yupAccountHolder,
  yupBic,
  yupIban,
} from 'utils/validation/yupBankDetails';
import { BankDetailsType } from 'models/oav/BankDetails.models';

interface DialogBankDetailsProps {
  project: Project;
  neededDocuments: NeededSupportingDocument;
  onClose: () => void;
  projectId: string;
  open: boolean;
}

export interface BankDetailsFormikValue {
  paymentFrequency: PaymentFrequency;
  paymentDay: PaymentDay;
  useCreditAccount: boolean;
  creditBankDetails: {
    accountHolder: string;
    iban: string;
    bic: string;
  };
  debitBankDetails: {
    accountHolder: string;
    iban: string;
    bic: string;
  };
}

type DialogBankDetailsContentProps = DialogBankDetailsProps & {
  previewDocument?: SupportingDocument;
  setPreviewDocument: Dispatch<SetStateAction<SupportingDocument | undefined>>;
  subscription: Subscription;
};

const validationBankDetails = Yup.object().shape({
  accountHolder: yupAccountHolder.required(messageFieldRequired),
  bic: yupBic.required(messageFieldRequired),
  iban: yupIban.required(messageFieldRequired),
});

const validationSchema = Yup.object().shape({
  paymentFrequency: Yup.string().required(messageFieldRequired),
  paymentDay: Yup.number().required(messageFieldRequired),
  useCreditAccount: Yup.boolean(),
  debitBankDetails: validationBankDetails,
  creditBankDetails: Yup.object().when('useCreditAccount', {
    is: true,
    then: () => validationBankDetails,
  }),
});

const DialogBankDetailsContent: FC<DialogBankDetailsContentProps> = ({
  onClose,
  projectId,
  previewDocument,
  setPreviewDocument,
  subscription,
  neededDocuments,
}) => {
  const { addSnackbar } = useAppContext();

  const debitId = subscription.debitBankDetails?.id;

  const debitDropzone = useUploadSupportingDocuments({
    neededDocuments,
    projectId,
    requestEnhancement: { bankDetailsType: BankDetailsType.DEBIT },
    filterFiles: debitId ? doc => doc.bankDetailsId === debitId : () => false,
  });

  let creditId = subscription.creditBankDetails?.id;

  if (creditId === debitId) {
    creditId = undefined;
  }

  const creditDropzone = useUploadSupportingDocuments({
    neededDocuments,
    projectId,
    requestEnhancement: { bankDetailsType: BankDetailsType.CREDIT },
    filterFiles: creditId ? doc => doc.bankDetailsId === creditId : () => false,
  });

  const mutation = mutations.useUpdateBankDetails({
    onSuccess: onClose,
    onError: error => {
      if (
        isBadRequestWithDetailsError<BankDetailsRequestErrors>(error) &&
        error.response?.details
      ) {
        onBadRequest(error.response?.details);
      } else {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      }
    },
  });
  const onBadRequest = (errorDetails: BankDetailsRequestErrors) => {
    if (errorDetails.creditBankDetails) {
      Object.entries(errorDetails.creditBankDetails).forEach(([key, value]) => {
        setFormikError(formikRef, `creditBankDetails.${key}`, value);
      });
    }
    if (errorDetails.debitBankDetails) {
      Object.entries(errorDetails.debitBankDetails).forEach(([key, value]) => {
        setFormikError(formikRef, `debitBankDetails.${key}`, value);
      });
    }
  };

  const formikRef = useRef<FormikProps<BankDetailsFormikValue>>(null);

  const initialValues: BankDetailsFormikValue = useMemo(() => {
    const debit = subscription.debitBankDetails;
    const credit = subscription.creditBankDetails;

    const useCreditAccount = !!credit?.id && credit.id !== debit?.id;
    return {
      paymentFrequency:
        subscription.paymentFrequency ?? PaymentFrequency.MONTHLY,
      paymentDay: subscription.paymentDay ?? PaymentDay.FIFTH,
      useCreditAccount,
      debitBankDetails: {
        accountHolder: debit?.accountHolder ?? '',
        bic: debit?.bic ?? '',
        iban: debit?.iban ?? '',
      },
      creditBankDetails: {
        accountHolder: useCreditAccount ? (credit?.accountHolder ?? '') : '',
        bic: useCreditAccount ? (credit?.bic ?? '') : '',
        iban: useCreditAccount ? (credit?.iban ?? '') : '',
      },
    };
  }, [subscription]);

  const handleSubmit = (values: BankDetailsFormikValue) => {
    mutation.mutate({
      projectId,
      subscriptionId: subscription.id,
      payload: {
        paymentDay: values.paymentDay,
        paymentFrequency: values.paymentFrequency,
        debitBankDetails: {
          bic: values.debitBankDetails.bic,
          iban: values.debitBankDetails.iban,
          accountHolder: values.debitBankDetails.accountHolder,
        },
        creditBankDetails: values.useCreditAccount
          ? {
              bic: values.creditBankDetails.bic,
              iban: values.creditBankDetails.iban,
              accountHolder: values.creditBankDetails.accountHolder,
            }
          : undefined,
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validateOnMount
    >
      {formikProps => {
        const { values, setFieldValue } = formikProps;
        return (
          <Form
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <DialogTitle>Coordonnées bancaires</DialogTitle>
            <ButtonClose
              disabled={
                debitDropzone.actionPending ||
                creditDropzone.actionPending ||
                mutation.isPending
              }
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            />
            <DialogContent sx={{ width: '100%' }}>
              <Grid container columnSpacing={4} sx={{ height: '100%' }}>
                {previewDocument && (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      height: '100%',
                    }}
                  >
                    <PreviewDocument
                      document={previewDocument}
                      onClose={() => setPreviewDocument(undefined)}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={previewDocument ? 6 : 12}
                  sx={{ height: '100%', overflow: 'auto' }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          padding: 2,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Informations bancaires pour le prélèvement des
                              cotisations :
                            </Typography>
                          </Grid>
                          <DropzoneBankDetails
                            previewDocument={previewDocument}
                            setPreviewDocument={setPreviewDocument}
                            projectId={projectId}
                            {...debitDropzone}
                          />
                          <Grid item xs={12}>
                            <FormBankDetails
                              formikProps={formikProps}
                              name="debitBankDetails"
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Périodicité de prélèvements souhaitée
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ToggleButtonGroupSplit
                        value={values.paymentFrequency}
                        exclusive
                        onChange={(e, v) =>
                          v && setFieldValue('paymentFrequency', v)
                        }
                      >
                        {Object.entries(paymentFrequencyLabels)
                          .sort((a, b) => a[1].order - b[1].order)
                          .map(([key, _]) => (
                            <ToggleButtonSplit
                              key={key}
                              fullWidth
                              value={key}
                              color="primary"
                            >
                              {_.label}
                            </ToggleButtonSplit>
                          ))}
                      </ToggleButtonGroupSplit>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Date de prélèvements souhaitée
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ToggleButtonGroupSplit
                        value={values.paymentDay}
                        exclusive
                        onChange={(e, v) => v && setFieldValue('paymentDay', v)}
                      >
                        {Object.values(PaymentDay)
                          .filter(_ => !isNaN(Number(_)))
                          .sort((a, b) => (a as PaymentDay) - (b as PaymentDay))
                          .map(_ => (
                            <ToggleButtonSplit
                              key={_}
                              fullWidth
                              value={_}
                              color="primary"
                            >
                              {_}
                            </ToggleButtonSplit>
                          ))}
                      </ToggleButtonGroupSplit>
                    </Grid>

                    <Grid item xs={12}>
                      <Card
                        sx={{
                          padding: 2,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<Switch />}
                              sx={{ ml: 0 }}
                              labelPlacement="start"
                              checked={values.useCreditAccount}
                              onChange={(e, v) =>
                                setFieldValue('useCreditAccount', v)
                              }
                              label={
                                <>
                                  Souhaitez-vous un{' '}
                                  <b>compte bancaire différent </b>pour le
                                  <b> versement des prestations</b> ?
                                </>
                              }
                            />
                          </Grid>
                          {values.useCreditAccount && (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="body1">
                                  Informations bancaires pour le versement des
                                  prestations :
                                </Typography>
                              </Grid>
                              <DropzoneBankDetails
                                previewDocument={previewDocument}
                                setPreviewDocument={setPreviewDocument}
                                projectId={projectId}
                                {...creditDropzone}
                              />
                              <Grid item xs={12}>
                                <FormBankDetails
                                  formikProps={formikProps}
                                  name="creditBankDetails"
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 2,
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item sm="auto" xs={12}>
                  <Button
                    fullWidth
                    variant="text"
                    color="default"
                    onClick={onClose}
                    disabled={
                      debitDropzone.actionPending ||
                      creditDropzone.actionPending ||
                      mutation.isPending
                    }
                    sx={{
                      px: 4,
                    }}
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item sm="auto" xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="gradient"
                    color="primary"
                    disabled={
                      debitDropzone.actionPending ||
                      creditDropzone.actionPending ||
                      mutation.isPending
                    }
                    sx={{
                      px: 4,
                    }}
                  >
                    {mutation.isPending ? (
                      <CircularProgress color="inherit" size={28} />
                    ) : (
                      'Valider'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

const DialogBankDetails: React.FC<DialogBankDetailsProps> = props => {
  const { open, project } = props;
  const subscription = project.subscriptions?.[0] as Subscription;

  const [previewDocument, setPreviewDocument] = useState<
    SupportingDocument | undefined
  >();
  useEffect(() => {
    if (!open) {
      setPreviewDocument(undefined);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={previewDocument ? 'xl' : 'md'}
      fullWidth
      sx={{
        height: previewDocument ? '100%' : undefined,
      }}
      PaperProps={{ sx: { height: previewDocument ? '100%' : undefined } }}
    >
      <DialogBankDetailsContent
        {...props}
        previewDocument={previewDocument}
        setPreviewDocument={setPreviewDocument}
        subscription={subscription}
      />
    </Dialog>
  );
};

export default memo(DialogBankDetails);
