import {RestError} from '../../errors/RestError';
import {captureError} from "../../sentry/SentryConfig";

export const post = async (
  url: string,
  body: any,
  accessToken: string,
  label = 'OAV-Error',
  headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }) => {

  const strBody = JSON.stringify(body);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
    body: strBody,
  });

  return await handleResponse(response, 'POST', url, strBody, label);
}

export const get = async (
  url: string,
  accessToken: string,
  label = 'OAV-Error',
  headers = {
    Accept: 'application/json',
  }) => {

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return await handleResponse(response, 'GET', url, undefined, label);
}

const handleResponse = async (
  response: Response,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  url: string,
  strBody: string | undefined,
  label: string,
): Promise<any> => {

  if (response.status / 100 >= 4) {
    const error = await response.json();
    captureError(label,
      response.status,
      { response: error },
    );
    throw new RestError(
      response.status,
      url,
      method,
      strBody,
      error,
    );
  }

  return await response.json();
}