import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { IconButton } from '@mui/material';
import { Icon } from 'components/Icon';
import { CloseIcon } from 'components/Icon/CloseIcon';
import React from 'react';
import { useTheme } from '@mui/material/styles';

type ButtonCloseProps = { iconColor?: string } & IconButtonProps;
const ButtonClose: React.FC<ButtonCloseProps> = ({ iconColor, ...props }) => {
  const theme = useTheme();
  return (
    <IconButton
      aria-label="close"
      {...props}
      sx={{
        ...props.sx,
        ':hover': {
          backgroundColor: 'rgba(250, 250, 250, 0.04)',
        },
      }}
    >
      <Icon color={iconColor ? iconColor : theme.palette.text.primary}>
        <CloseIcon />
      </Icon>
    </IconButton>
  );
};

export default ButtonClose;
