import React, { ReactElement } from 'react';
import { useQuery } from '@tanstack/react-query';

import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { IconProps } from 'components/Icon/settings';
import {
  supportingDocumentIcons,
  SupportingDocumentTypeCode,
} from 'models/referentiels/Document.models';
import ButtonClose from 'components/Button/ButtonClose';
import { FileContext } from 'utils/project/supportingDocuments';
import { supportingDocumentsQueries } from 'api/oav/SupportingDocumentQuery.api';
import { useAppContext } from 'components/Context/AppContext';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { ViewIcon } from 'components/Icon/ViewIcon';
import { SupportingDocument } from 'models/oav/SupportingDocument.models';

interface CardSupportingDocumentsProps {
  file: FileContext;
  type: SupportingDocumentTypeCode;
  onDelete: () => void;
  projectId: string;
  onPreview?: (document: SupportingDocument) => void;
  viewing?: boolean;
}

const LoadingGateway: React.FC<{
  loading: boolean;
  children: ReactElement;
}> = ({ loading, children }) => {
  if (loading) {
    return <CircularProgress color="inherit" size={25} sx={{ mx: 1 }} />;
  }

  return children;
};

const CardSupportingDocuments: React.FC<CardSupportingDocumentsProps> = ({
  file,
  type,
  onDelete,
  projectId,
  onPreview,
  viewing,
}) => {
  const theme = useTheme();

  const { addSnackbar } = useAppContext();

  const { isFetching: isDocumentFetching, refetch } = useQuery({
    ...supportingDocumentsQueries.getById(projectId, file.id || ''),
    enabled: false,
  });

  const actionPending = file.deleting || file.uploading || isDocumentFetching;

  const addSnackBarError = () => {
    addSnackbar(
      getDefaultErrorSnackBar(
        'Une erreur est survenue lors de la visualisation du document.',
      ),
    );
  };
  const downloadAndViewDocument = () => {
    if (file.id) {
      refetch()
        .then(res => {
          const document = res.data;
          if (document?.downloadUrl) {
            onPreview?.(document);
          } else {
            addSnackBarError();
          }
        })
        .catch(() => {
          addSnackBarError();
        });
    }
  };

  return (
    <Card
      sx={{
        pl: 2,
        border: `2px solid ${theme.palette.divider}`,
        position: 'relative',
      }}
    >
      {file.uploading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            width: '100%',
            height: '2px',
            top: 0,
            left: 0,
            background: theme.palette.background.paper,
            '&.MuiLinearProgress-bar': {
              transition: 'transform 0.3s linear',
            },
          }}
        />
      )}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
      >
        {React.createElement<IconProps>(supportingDocumentIcons[type].icon, {
          color: theme.palette.text.disabled,
          width: '70',
          height: '70',
        })}
        <Typography
          variant="caption"
          sx={{ color: theme.palette.text.primary }}
        >
          {file.filename}
        </Typography>
        <Stack
          direction="row"
          sx={{ ml: 'auto' }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <LoadingGateway loading={isDocumentFetching}>
            <IconButton
              onClick={downloadAndViewDocument}
              disabled={actionPending}
              sx={{
                ':hover': {
                  backgroundColor: 'rgba(250, 250, 250, 0.04)',
                },
              }}
            >
              <ViewIcon
                color={
                  viewing
                    ? theme.palette.background.paper
                    : theme.palette.text.disabled
                }
                fill={viewing ? theme.palette.text.primary : undefined}
              />
            </IconButton>
          </LoadingGateway>
          <LoadingGateway loading={file.deleting}>
            <ButtonClose
              disabled={actionPending}
              onClick={onDelete}
              iconColor={theme.palette.text.disabled}
            />
          </LoadingGateway>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CardSupportingDocuments;
