import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import {
  DropEvent,
  DropzoneOptions,
  ErrorCode,
  FileRejection,
  useDropzone,
} from 'react-dropzone';

import { lighten, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAppContext } from 'components/Context/AppContext';
import { UploadIcon } from 'components/Icon/UploadIcon';

import { formatBytes, MimeType } from 'utils/document/documentUtils';
import { buildAccept, getExtensions } from 'utils/document/dropZoneUtils';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';

export interface DropzoneDocumentRef {
  open: () => void;
}

export type DropzoneDocumentProps = {
  label: React.ReactNode;
  helper?: string;
  maxSize?: number;
  acceptedTypes?: MimeType[];
  nbFiles: number;
} & DropzoneOptions;

export const DropzoneDocument = forwardRef(
  (props: DropzoneDocumentProps, ref: any) => {
    const { addSnackbar } = useAppContext();

    const theme = useTheme();
    const maxFiles = props.maxFiles !== undefined ? props.maxFiles : 1;

    useImperativeHandle(ref, () => ({
      open: () => {
        open();
      },
    }));

    const handleDrop = useCallback(
      (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
        event: DropEvent,
      ) => {
        if (props.nbFiles == maxFiles) {
          if (rejectedFiles.length == 0) {
            addSnackbar(
              getDefaultErrorSnackBar(
                'Nombre de fichier maximum déjà atteint.',
              ),
            );
          }
          return;
        }
        props.onDrop?.(acceptedFiles, rejectedFiles, event);
      },
      [props.nbFiles, maxFiles, props.onDrop],
    );

    const onDropRejected = (fileRejections: FileRejection[]) => {
      const errorType = fileRejections[0]?.errors[0].code;
      switch (errorType) {
        case ErrorCode.FileInvalidType:
          addSnackbar(getDefaultErrorSnackBar('Format de fichier invalide.'));
          break;
        case ErrorCode.TooManyFiles:
          addSnackbar(
            getDefaultErrorSnackBar('Nombre de fichier maximum déjà atteint.'),
          );
          break;
        case ErrorCode.FileTooLarge:
          addSnackbar(getDefaultErrorSnackBar('Fichier trop volumineux.'));
          break;
        default:
          addSnackbar(
            getDefaultErrorSnackBar("Impossible d'ajouter le fichier."),
          );
      }
    };

    const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
      ...props,
      noClick: true,
      noKeyboard: true,
      accept: props.acceptedTypes && buildAccept(props.acceptedTypes),
      maxFiles: maxFiles - props.nbFiles,
      maxSize: props.maxSize,
      onDropRejected,
      onDrop: handleDrop,
    });

    return (
      <Card
        {...getRootProps({
          sx: {
            width: '100%',
            height: '100%',
            p: 2,
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: isDragActive
              ? lighten(theme.palette.background.paper, 0.1)
              : theme.palette.background.paper,
            backgroundImage: `url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='${encodeURIComponent(theme.palette.text.secondary)}' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")`,
            borderRadius: 4,
          },
        })}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={{
            sm: 1,
            xs: 2,
          }}
          sx={{ height: '100%' }}
        >
          <UploadIcon
            width="80"
            height="80"
            color={theme.palette.background.default}
          />

          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            <Link
              underline="always"
              onClick={open}
              sx={{ textDecoration: 'underline' }}
            >
              <input
                {...getInputProps({
                  type: 'file',
                  disabled: maxFiles <= props.nbFiles,
                  hidden: true,
                })}
              />
              Cliquez ici
            </Link>{' '}
            {props.label}
          </Typography>

          {props.helper ? (
            <Typography variant="caption">{props.helper}</Typography>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
              divider={<Typography variant="caption">-</Typography>}
            >
              {props.maxSize && (
                <Typography variant="caption">
                  Taille maximale : {formatBytes(props.maxSize)}
                </Typography>
              )}
              {props.acceptedTypes && (
                <Typography variant="caption">
                  Formats acceptés :{' '}
                  {getExtensions(props.acceptedTypes).join(', ')}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Card>
    );
  },
);
