import { cloneElement } from 'react';
import { getSize, IconProps } from './settings';
import { useTheme } from '@mui/material/styles';
import { getPaletteColor } from 'static/themes/theme';

type Props = IconProps & {
  children: any;
};

export const Icon = (props: Props) => {
  const theme = useTheme();

  const size = getSize(props.size || 'medium');
  const iconWidth = props.width || size.width;
  const iconHeight = props.height || size.height;

  const color: string = props.color
    ? getPaletteColor(props.color, theme)
    : 'currentColor';

  return cloneElement(props.children as any, {
    ...props,
    width: iconWidth,
    height: iconHeight,
    color: color,
  });
};
