import { PaletteOptions } from '@mui/material/styles';

export const paletteDark: PaletteOptions = {
  primary: {
    main: '#7E96EB',
    contrastText: '#EDF0FC',
  },
  secondary: {
    main: '#F89136',
    contrastText: '#EDF0FC',
  },
  error: {
    main: '#F23A3A',
  },
  warning: {
    main: '#F89136',
    contrastText: '#EDF0FC',
  },
  info: {
    main: '#2155CD',
  },
  success: {
    main: '#52E09C',
    contrastText: '#EDF0FC',
  },
  text: {
    primary: '#EDF0FC',
    secondary: '#DDDFEB',
    disabled: '#DDDFEB',
  },
  background: {
    default: '#34363D',
    paper: '#43454B',
  },
  action: {
    disabled: '#DDDFEB',
    disabledBackground: '#83848B',
  },
  divider: '#464D67',
};

export const opacityDark = {
  card: 0.2,
  chip: 0.2,
  disabled: 0.2,
  field: 0.1,
  focused: 0.1,
  hover: 0.1,
  pre: 0.2,
  scrollbarThumb: 0.8,
  tooltip: 0.2,
};
