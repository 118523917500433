import { MimeType, mimeTypeDetails } from './documentUtils';

export const DEFAULT_FILE_MAX_SIZE = 10485760;
export const DEFAULT_FILE_ACCEPT = [
  MimeType.APPLICATION_PDF,
  MimeType.IMAGE_BMP,
  MimeType.IMAGE_JPG,
  MimeType.IMAGE_PNG,
  MimeType.IMAGE_WEBP,
];

export const getExtensions = (mimeTypes: MimeType[]): string[] => {
  return mimeTypes.flatMap(mimeType => {
    const details = mimeTypeDetails[mimeType];
    return details ? details.extensions : [];
  });
};

export const buildAccept = (
  mimeTypes: MimeType[],
): Record<string, string[]> => {
  return mimeTypes.reduce(
    (acceptObject, mimeType) => {
      const details = mimeTypeDetails[mimeType];
      if (details) {
        acceptObject[mimeType] = details.extensions.map(ext => `.${ext}`);
      }
      return acceptObject;
    },
    {} as Record<string, string[]>,
  );
};
