import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import {
  NeededSupportingDocument,
  SupportingDocument,
} from 'models/oav/SupportingDocument.models';
import ButtonClose from 'components/Button/ButtonClose';
import { DropzoneDocument } from 'components/Dropzone/DropzoneDocument';
import { useUploadSupportingDocuments } from 'utils/project/supportingDocuments';
import CardSupportingDocuments from 'content/project/supportingDocument/CardSupportingDocuments';
import PreviewDocument from 'content/project/supportingDocument/PreviewDocument';
import {
  DEFAULT_FILE_ACCEPT,
  DEFAULT_FILE_MAX_SIZE,
} from 'utils/document/dropZoneUtils';

interface DialogIdProps {
  neededDocuments: NeededSupportingDocument;
  onClose: () => void;
  projectId: string;
  open: boolean;
}

const IdType = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  return <li style={{ marginTop: theme.spacing(0.5) }}>{children}</li>;
};

type DialogIdContentProps = DialogIdProps &
  ReturnType<typeof useUploadSupportingDocuments> & {
    previewDocument?: SupportingDocument;
    setPreviewDocument: Dispatch<
      SetStateAction<SupportingDocument | undefined>
    >;
  };

const DialogIdContent: FC<DialogIdContentProps> = ({
  actionPending,
  files,
  onDrop,
  previewDocument,
  onClose,
  onDelete,
  setPreviewDocument,
  projectId,
  neededDocuments,
}) => {
  const theme = useTheme();
  return (
    <>
      <DialogTitle>Identité du souscripteur</DialogTitle>
      <ButtonClose
        disabled={actionPending}
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      />
      <DialogContent sx={{ width: '100%' }}>
        <Grid container columnSpacing={4} sx={{ height: '100%' }}>
          {previewDocument && (
            <Grid
              item
              xs={8}
              sx={{
                height: '100%',
              }}
            >
              <PreviewDocument
                document={previewDocument}
                onClose={() => setPreviewDocument(undefined)}
              />
            </Grid>
          )}
          <Grid
            item
            xs={previewDocument ? 4 : 12}
            sx={{ height: '100%', overflow: 'auto' }}
          >
            <Typography variant="body1" sx={{ mb: 1 }}>
              Veuillez téléverser la pièce d&apos;identité en cours de validité
              du souscripteur :
            </Typography>
            <ul style={{ marginBottom: theme.spacing(2) }}>
              <IdType>Carte Nationale d&apos;Identité française</IdType>
              <IdType>Passeport français ou européen</IdType>
              <IdType>Titre de séjour en France</IdType>
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DropzoneDocument
                  label={
                    <>
                      ou glissez et déposez{' '}
                      <strong>la pièce d&apos;identité</strong> ici.
                    </>
                  }
                  acceptedTypes={DEFAULT_FILE_ACCEPT}
                  maxSize={DEFAULT_FILE_MAX_SIZE}
                  maxFiles={2}
                  nbFiles={files.length}
                  onDrop={onDrop}
                />
              </Grid>
              {files.map((_, index) => (
                <Grid key={index} item xs={12}>
                  <CardSupportingDocuments
                    projectId={projectId}
                    file={_}
                    type={neededDocuments.type}
                    onDelete={onDelete(_)}
                    onPreview={setPreviewDocument}
                    viewing={_.id ? previewDocument?.id === _.id : false}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              onClick={onClose}
              disabled={actionPending}
              sx={{
                px: 4,
              }}
            >
              Annuler
            </Button>
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="gradient"
              color="primary"
              disabled={actionPending}
              onClick={onClose}
              sx={{
                px: 4,
              }}
            >
              Valider
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const DialogId: React.FC<DialogIdProps> = props => {
  const { neededDocuments, onClose, projectId, open } = props;

  const { files, onDelete, onDrop, actionPending } =
    useUploadSupportingDocuments({ neededDocuments, projectId });

  const [previewDocument, setPreviewDocument] = useState<
    SupportingDocument | undefined
  >();
  useEffect(() => {
    if (!open) {
      setPreviewDocument(undefined);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={actionPending ? undefined : onClose}
      maxWidth={previewDocument ? 'xl' : 'md'}
      fullWidth
      sx={{
        height: previewDocument ? '100%' : undefined,
      }}
      PaperProps={{ sx: { height: previewDocument ? '100%' : undefined } }}
    >
      <DialogIdContent
        {...props}
        onClose={onClose}
        files={files}
        actionPending={actionPending}
        onDelete={onDelete}
        onDrop={onDrop}
        previewDocument={previewDocument}
        setPreviewDocument={setPreviewDocument}
      />
    </Dialog>
  );
};

export default DialogId;
