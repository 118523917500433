import { useCallback, useMemo, useState } from 'react';

import { produce } from 'immer';

import { useAppContext } from 'components/Context/AppContext';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import {
  NeededSupportingDocument,
  SupportingDocument,
  UploadSupportingDocumentRequest,
} from 'models/oav/SupportingDocument.models';
import { supportingDocumentMutations } from 'api/oav/SupportingDocumentQuery.api';

export interface FileContext {
  filename: string;
  id?: string;
  file?: File;
  uploading: boolean;
  deleting: boolean;
}

export const useUploadSupportingDocuments = ({
  neededDocuments,
  projectId,
  requestEnhancement,
  filterFiles,
}: {
  neededDocuments: NeededSupportingDocument;
  projectId: string;
  requestEnhancement?: Partial<UploadSupportingDocumentRequest>;
  filterFiles?: (document: SupportingDocument) => boolean;
}) => {
  const { addSnackbar } = useAppContext();

  const initialDocuments = useMemo(() => {
    const docs = filterFiles
      ? neededDocuments.documents?.filter(filterFiles)
      : neededDocuments.documents;
    return (
      docs?.map(_ => ({
        filename: _.filename,
        id: _.id,
        uploading: false,
        deleting: false,
      })) || []
    );
  }, []);
  const [files, setFiles] = useState<FileContext[]>(initialDocuments);

  const deleteMutation = supportingDocumentMutations.useDelete({
    onSuccess: (data, variables) => {
      setFiles(_ => _.filter(f => f.id !== variables.supportingDocumentId));
    },
    onError: (err, variables) => {
      setFiles(
        produce(files => {
          const f = files.find(_ => _.id === variables.supportingDocumentId);
          if (f) {
            f.deleting = false;
          }
          return files;
        }),
      );
      addSnackbar(
        getDefaultErrorSnackBar(
          'Impossible de supprimer la pièce, une erreur est survenue.',
        ),
      );
    },
  });

  const uploadMutation = supportingDocumentMutations.useUpload({
    onSuccess: (data, variables) => {
      setFiles(
        produce(files => {
          const f = files.find(_ => _.file === variables.file);
          if (f) {
            f.uploading = false;
            f.id = data.id;
          }
          return files;
        }),
      );
    },
    onError: (err, variables) => {
      setFiles(files => files.filter(_ => _.file !== variables.file));
      addSnackbar(
        getDefaultErrorSnackBar(
          "Impossible d'ajouter la pièce, une erreur est survenue.",
        ),
      );
    },
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map(_ => ({
        filename: _.name,
        file: _,
        uploading: true,
        deleting: false,
      }));
      setFiles(_ => [..._, ...newFiles]);
      newFiles.forEach(_ => {
        uploadMutation.mutate({
          projectId,
          file: _.file,
          payload: {
            ...requestEnhancement,
            type: neededDocuments.type,
          },
        });
      });
    },
    [files],
  );

  const onDelete = (file: FileContext) => () => {
    if (!file.id) {
      return;
    }

    deleteMutation.mutate({ projectId, supportingDocumentId: file.id });
    setFiles(
      produce(files => {
        const f = files.find(f => f.id === file.id);
        if (f) {
          f.deleting = true;
        }
        return files;
      }),
    );
  };

  const actionPending = deleteMutation.isPending || uploadMutation.isPending;

  return {
    actionPending,
    onDrop,
    onDelete,
    files,
  };
};
