import { createRef, useState } from 'react';

import { alpha, SxProps, Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import TableChartIcon from '@mui/icons-material/TableChart';

import PriceNumber from 'content/formula/PriceNumber';
import FormulaDialogWarranty from 'content/formula/FormulaDialogWarranty';

import { Formula } from 'models/referentiels/Formula.model';
import { TrfPricing } from 'models/referentiels/Tarification.models';
import { Offer } from 'models/referentiels/Offer.model';

export type CardFormulaProps = {
  offer?: Offer;
  formula: Formula;
  pricing?: TrfPricing;
  selected?: boolean;
  disabled?: boolean;
  recommended?: boolean;
  enableWarranty?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme> | undefined;
};

const CardFormula = (props: CardFormulaProps) => {
  const theme = useTheme();
  const refContainer = createRef<HTMLDivElement>();

  const [showWarranties, setShowWarranties] = useState<boolean>(false);

  const buttonOpenWarranties = (
    <Tooltip title="Afficher les garanties" placement="left">
      <Button
        color="dark"
        onClick={e => {
          e.stopPropagation();
          setShowWarranties(true);
        }}
        sx={{
          minWidth: 'auto',
          borderRadius: 3,
          p: 1,
          backgroundColor: alpha('#000000', 0.1),
          ':hover': {
            backgroundColor: alpha('#000000', 0.2),
          },
        }}
      >
        <TableChartIcon />
      </Button>
    </Tooltip>
  );

  return (
    <Card
      ref={refContainer}
      sx={{
        ...props.sx,
        position: 'relative',
        overflow: 'visible',
        height: '100%',
        py: 1,
        px: {
          md: 1,
          xs: 1,
        },
        border: 2,
        borderColor: props.selected
          ? theme.palette.primary.main
          : theme.palette.divider,
        cursor: props.onClick && !props.disabled ? 'pointer' : 'default',
        transition: 'transform 150ms ease-in-out',
        ':hover': {
          transform: {
            md: props.onClick && !props.disabled ? 'translateY(-6px)' : 'none',
            xs: 'none',
          },
        },
      }}
      onClick={() => !props.disabled && props.onClick?.()}
    >
      {props.recommended && (
        <Box
          sx={{
            position: 'absolute',
            top: -20,
            fontWeight: 700,
            width: 'calc(100% - 16px)',
            textAlign: 'center',
          }}
        >
          <Chip color="primary" label="Notre conseil" size="small" />
        </Box>
      )}

      <Grid
        container
        spacing={1}
        alignItems="stretch"
        sx={{
          containerType: 'inline-size',
        }}
      >
        <Grid item xs>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            columnGap={1}
            sx={{
              height: '100%',
              flexWrap: {
                md: 'wrap',
                xs: 'nowrap',
              },
            }}
          >
            {props.onClick && (
              <Checkbox checked={props.selected} disabled={props.disabled} />
            )}
            <Box
              sx={{
                flexGrow: 2,
                width: {
                  md: '100%',
                  xs: 'auto',
                },
                minWidth: 0,
                containerType: 'inline-size',
              }}
            >
              <Typography
                sx={{
                  color: props.formula.color || theme.palette.text.primary,
                  textOverflow: 'ellipsis',
                }}
                noWrap
                fontWeight={700}
              >
                {props.formula.label}
              </Typography>
              <Typography variant="caption">
                {props.formula.description}
              </Typography>
            </Box>
          </Stack>
        </Grid>

        {props.enableWarranty && (
          <Grid
            item
            xs="auto"
            sx={{
              display: {
                sm: 'none',
                xs: 'flex',
              },
            }}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              {buttonOpenWarranties}
            </Stack>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          <Divider />
        </Grid>

        <Grid
          item
          md={12}
          sm="auto"
          xs={12}
          sx={{
            '@container (max-width: 400px)': {
              flexBasis: '100%',
            },
            '@container (min-width: 400px)': {
              minWidth: '200px',
            },
            textAlign: {
              md: 'left',
              xs: 'right',
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            columnGap={2}
            sx={{
              containerType: 'inline-size',
              width: '100%',
            }}
          >
            <Typography
              variant="caption"
              noWrap
              sx={{
                '@container (max-width: 150px)': {
                  textAlign: 'right !important',
                  width: '100% !important',
                },
                textAlign: {
                  md: 'right',
                  xs: 'left',
                },
              }}
            >
              par mois
            </Typography>
            <Typography
              variant="body1"
              noWrap
              sx={{
                '@container (max-width: 150px)': {
                  textAlign: 'right !important',
                  width: '100% !important',
                },
                textAlign: 'right',
              }}
            >
              <PriceNumber
                price={props.pricing?.perMonth}
                currency={props.pricing?.currency}
              />
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            columnGap={2}
            sx={{
              width: '100%',
              containerType: 'inline-size',
            }}
          >
            <Typography
              variant="caption"
              noWrap
              sx={{
                '@container (max-width: 150px)': {
                  textAlign: 'right !important',
                  width: '100% !important',
                },
                textAlign: {
                  md: 'right',
                  xs: 'left',
                },
              }}
            >
              par an
            </Typography>
            <Typography
              variant="body1"
              noWrap
              sx={{
                '@container (max-width: 150px)': {
                  textAlign: 'right !important',
                  width: '100% !important',
                },
                textAlign: 'right',
              }}
            >
              <PriceNumber
                price={props.pricing?.perYear}
                currency={props.pricing?.currency}
              />
            </Typography>
          </Stack>
        </Grid>

        {props.enableWarranty && (
          <Grid
            item
            xs="auto"
            sx={{
              display: {
                md: 'none',
                sm: 'flex',
                xs: 'none',
              },
            }}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Divider orientation="vertical" sx={{ pl: 1, height: '60%' }} />
              {buttonOpenWarranties}
            </Stack>
          </Grid>
        )}
      </Grid>

      {props.offer && props.enableWarranty && (
        <Box
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <FormulaDialogWarranty
            open={showWarranties}
            offer={props.offer}
            formula={props.formula}
            pricing={props.pricing}
            onClose={() => setShowWarranties(false)}
          />
        </Box>
      )}
    </Card>
  );
};

export default CardFormula;
