import React, { Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';

import { SupportingDocument } from 'models/oav/SupportingDocument.models';
import { DropzoneDocument } from 'components/Dropzone/DropzoneDocument';
import { FileContext } from 'utils/project/supportingDocuments';
import CardSupportingDocuments from 'content/project/supportingDocument/CardSupportingDocuments';
import { SupportingDocumentTypeCode } from 'models/referentiels/Document.models';
import {
  DEFAULT_FILE_ACCEPT,
  DEFAULT_FILE_MAX_SIZE,
} from 'utils/document/dropZoneUtils';

interface DropzoneBankDetailsProps {
  setPreviewDocument: Dispatch<SetStateAction<SupportingDocument | undefined>>;
  previewDocument?: SupportingDocument;
  onDelete: (file: FileContext) => () => void;
  onDrop: (acceptedFiles: File[]) => void;
  projectId: string;
  files: FileContext[];
}

const DropzoneBankDetails: React.FC<DropzoneBankDetailsProps> = ({
  setPreviewDocument,
  previewDocument,
  onDelete,
  projectId,
  files,
  onDrop,
}) => {
  return (
    <>
      {files.length > 0 ? (
        <>
          {files.map((_, index) => (
            <Grid key={index} item xs={12}>
              <CardSupportingDocuments
                projectId={projectId}
                file={_}
                type={SupportingDocumentTypeCode.BANK_DETAILS}
                onDelete={onDelete(_)}
                onPreview={setPreviewDocument}
                viewing={_.id ? previewDocument?.id === _.id : false}
              />
            </Grid>
          ))}
        </>
      ) : (
        <Grid item xs={12}>
          <DropzoneDocument
            label={
              <>
                ou glissez et déposez <strong>le RIB</strong> ici.
              </>
            }
            acceptedTypes={DEFAULT_FILE_ACCEPT}
            maxSize={DEFAULT_FILE_MAX_SIZE}
            maxFiles={1}
            nbFiles={files.length}
            onDrop={onDrop}
          />
        </Grid>
      )}
    </>
  );
};

export default DropzoneBankDetails;
