import { Icon } from '.';
import { IconProps } from './settings';

export const InfoIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="none"
        viewBox="0 0 30 30"
      >
        <circle
          cx="15"
          cy="15"
          r="14"
          stroke={props.color}
          strokeWidth="2"
          transform="rotate(180 15 15)"
        ></circle>
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth="2"
          d="M15 13v11M15 8v1"
        ></path>
      </svg>
    </Icon>
  );
};
