import React from 'react';
import { FormikProps } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from 'components/TextField/TextField';
import { IBANTextField } from 'components/TextField/formatted/IBANTextField';
import {
  accountHolderMaxChar,
  accountHolderMinChar,
} from 'utils/validation/yupBankDetails';
import { BICTextField } from 'components/TextField/formatted/BICTextField';
import { getErrorField, getHelperTextField } from 'utils/fields/fields';
import { BankDetailsFormikValue } from './DialogBankDetails';

export interface BankDetailsFormValues {
  accountHolder: string;
  iban: string;
  bic: string;
}

interface BankDetailsFormProps {
  name: 'creditBankDetails' | 'debitBankDetails';
  formikProps: FormikProps<BankDetailsFormikValue>;
}

const BankDetailsForm: React.FC<BankDetailsFormProps> = ({
  name,
  formikProps,
}) => {
  const {
    handleChange,
    values: allValues,
    errors,
    touched,
    handleBlur,
  } = formikProps;
  const values = allValues[name];
  const accountHolderName = `${name}.accountHolder`;
  const ibanName = `${name}.iban`;
  const bicName = `${name}.bic`;
  return (
    <Grid
      container
      spacing={{
        sm: 2,
        xs: 1,
      }}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id={accountHolderName}
          name={accountHolderName}
          label="Titulaire du compte"
          value={values.accountHolder}
          onChange={handleChange}
          inputProps={{
            minLength: accountHolderMinChar,
            maxLength: accountHolderMaxChar,
          }}
          error={getErrorField(errors, touched, accountHolderName)}
          helperText={getHelperTextField(errors, touched, accountHolderName)}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={8}>
        <IBANTextField
          fullWidth
          required
          id={ibanName}
          name={ibanName}
          label="IBAN"
          value={values.iban}
          onChange={handleChange}
          error={getErrorField(errors, touched, ibanName)}
          helperText={getHelperTextField(errors, touched, ibanName)}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={4}>
        <BICTextField
          fullWidth
          required
          id={bicName}
          name={bicName}
          label="BIC"
          value={values.bic}
          onChange={handleChange}
          error={getErrorField(errors, touched, bicName)}
          helperText={getHelperTextField(errors, touched, bicName)}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  );
};

export default BankDetailsForm;
