//@ts-ignore
import AnimatedNumber from 'animated-number-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import { appName } from 'App';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import { navigateToPreviousStep } from 'utils/project/project';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { ChipOfferRisk } from 'components/Chip/ChipOfferRisk';
import { ChipProjectStatus } from 'components/Chip/ChipProjectStatus';
import DialogSignatureUpload from 'content/project/signature/DialogSignatureUpload';
import DialogDigitalSignature from 'content/project/contract/DialogDigitalSignature';
import PreviewDocument from 'content/project/supportingDocument/PreviewDocument';
import { CommercialProposition } from 'models/oav/CommercialProposition.models';
import { commercialPropositionMutations } from 'api/oav/CommercialPropositionQuery.api';

const CURRENT_STEP = ProjectStep.SIGNATURE;

const ProjectSignaturePage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!id) return <></>;

  const [showDialogSignManual, setShowDialogSignManual] =
    useState<boolean>(false);
  const [showDialogSignAuto, setShowDialogSignAuto] = useState<boolean>(false);

  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));
  const [commercialProposition, setCommercialProposition] =
    useState<CommercialProposition | null>(null);
  const commercialPropositionMutation =
    commercialPropositionMutations.useCreate(
      setCommercialProposition,
      () => {},
    );

  const subscription = projectQuery.data?.subscriptions?.[0];
  const tarificationsQuery = useQuery({
    ...subscriptionQueries.getByIdTarification(id, subscription?.id || ''),
    enabled: !!subscription?.id,
  });

  const refSent = useRef<boolean>(false);
  useEffect(() => {
    if (projectQuery.data?.id && !refSent.current) {
      refSent.current = true;
      commercialPropositionMutation.mutate({
        projectId: projectQuery.data?.id,
        payload: {
          sendEmail: false,
        },
      });
    }
  }, [projectQuery.data?.id]);

  const error = projectQuery.error;
  if (error) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={error instanceof RestError ? error.status : undefined}
        />
      </Grid>
    );
  }

  if (!projectQuery.data) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const offerCode = subscription!.offerCode;
  const offer = offersMapQuery.data?.[offerCode];
  const formulaCode = subscription!.formulaCode!;
  const formula = offer?.formulas?.find(_ => _.code === formulaCode);
  const pricing = tarificationsQuery.data?.formulas?.find(
    f => f.code === formulaCode,
  )?.tarification?.pricing;
  const subscriber = projectQuery.data?.subscriber?.person;

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Signature</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{
                p: 2,
                borderColor: theme.palette.divider,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Votre projet de souscription est complet et peut être signé
                    par votre client.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 2,
                      border: 2,
                      borderColor: theme.palette.divider,
                      background: theme.palette.background.default,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <Stack
                          gap={1}
                          alignItems="space-between"
                          sx={{
                            height: '100%',
                          }}
                        >
                          <Typography variant="body1">
                            <b>
                              {subscriber.civility} {subscriber.firstname}{' '}
                              {subscriber.lastname}
                            </b>
                          </Typography>
                          <Typography variant="body2">
                            <Stack
                              direction="row"
                              gap={1}
                              flexWrap="wrap-reverse"
                              useFlexGap
                            >
                              <b>{offer?.label}</b>
                              {offer?.risks?.map(_ => (
                                <ChipOfferRisk key={_.code} risk={_} />
                              ))}
                            </Stack>
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color:
                                formula?.color || theme.palette.text.primary,
                            }}
                          >
                            {formula?.label}
                          </Typography>
                          <Typography variant="caption">
                            <pre style={{ display: 'inline' }}>
                              {pricing ? (
                                <AnimatedNumber
                                  value={pricing?.perMonth}
                                  duration={200}
                                  easing="linear"
                                  formatValue={(value: number) =>
                                    value.toLocaleString('fr-FR', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  }
                                />
                              ) : (
                                '-'
                              )}
                            </pre>{' '}
                            €/mois soit&nbsp;{' '}
                            <pre style={{ display: 'inline' }}>
                              {pricing ? (
                                <AnimatedNumber
                                  value={pricing?.perYear}
                                  duration={200}
                                  easing="linear"
                                  formatValue={(value: number) =>
                                    value.toLocaleString('fr-FR', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  }
                                />
                              ) : (
                                '-'
                              )}
                            </pre>{' '}
                            €/an
                          </Typography>
                        </Stack>
                      </Grid>
                      {projectQuery.data.status && (
                        <Grid item xs="auto">
                          <ChipProjectStatus
                            projectStatus={projectQuery.data.status}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Card>
                </Grid>

                {commercialPropositionMutation.error ? (
                  <Grid item xs={12}>
                    <CardError />
                  </Grid>
                ) : (
                  <Grid item xs={12} height="800px;">
                    <PreviewDocument
                      document={commercialProposition}
                      loading={commercialProposition === undefined}
                    />
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <Button
          fullWidth
          color="default"
          onClick={() => navigateToPreviousStep(CURRENT_STEP, id, navigate)}
          sx={{ px: 4 }}
        >
          Retour
        </Button>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <DialogSignatureUpload
          project={projectQuery.data}
          open={showDialogSignManual}
          onClose={() => setShowDialogSignManual(false)}
        />
        <Button
          fullWidth
          onClick={() => setShowDialogSignManual(true)}
          sx={{
            px: 4,
          }}
          disabled={!commercialProposition}
        >
          Signature manuelle
        </Button>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <DialogDigitalSignature
          open={showDialogSignAuto}
          project={projectQuery.data}
          onClose={() => setShowDialogSignAuto(false)}
        />
        <Button
          fullWidth
          onClick={() => setShowDialogSignAuto(true)}
          sx={{ px: 4 }}
          disabled={!commercialProposition}
        >
          Signature électronique
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectSignaturePage;
