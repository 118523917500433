import { Pagination } from 'models/Page.models';
import {
  Person,
  PersonRequest,
  PersonSearchCriteriaRequest,
} from 'models/oav/Person.models';
import { addParams } from 'utils/api/params';
import { RestError } from 'errors/RestError';
import { formatDate } from 'utils/api/api';

const url = `${process.env.REACT_APP_API_URI_BASE}/oav/person`;

export const postPerson = async (
  person: PersonRequest,
  accessToken: string,
): Promise<Person> => {
  const response = await fetch(url, {
    body: JSON.stringify({
      ...person,
      birthdate: formatDate(person.birthdate),
    }),
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new RestError(
      response.status,
      url,
      'POST',
      undefined,
      await response.json(),
    );
  }

  const json = await response.json();
  return {
    ...json,
    birthdate: json.birthdate && new Date(json.birthdate),
  } as Person;
};

export const putPerson = async (
  person: PersonRequest,
  uuid: string,
  accessToken: string,
): Promise<Person> => {
  const fullUrl = `${url}/${uuid}`;
  const response = await fetch(fullUrl, {
    body: JSON.stringify({
      ...person,
      birthdate: formatDate(person.birthdate),
    }),
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new RestError(
      response.status,
      fullUrl,
      'PUT',
      undefined,
      await response.json(),
    );
  }

  const json = await response.json();
  return {
    ...json,
    birthdate: json.birthdate && new Date(json.birthdate),
  } as Person;
};

export const fetchPerson = async (
  uuid: string,
  accessToken: string,
): Promise<Person> => {
  const fullUrl = `${url}/${uuid}`;
  const response = await fetch(fullUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });

  if (response.status !== 200) {
    throw new RestError(
      response.status,
      fullUrl,
      'GET',
      undefined,
      await response.json(),
    );
  }
  const json = await response.json();
  return {
    ...json,
    birthdate: json.birthdate && new Date(json.birthdate),
  } as Person;
};

interface PersonListResponse {
  page: number;
  pageSize: number;
  persons: Person[];
  total: number;
}

export const fetchPersonList = async (
  pagination: Pagination,
  criteria: PersonSearchCriteriaRequest,
  accessToken: string,
): Promise<PersonListResponse> => {
  const params = new URLSearchParams();
  params.append('page', pagination.page!.toString());
  params.append('pageSize', pagination.size!.toString());
  const formattedCriteria = {
    ...criteria,
    birthdate: formatDate(criteria.birthdate),
  };
  addParams(params, formattedCriteria, 'criterias');

  const fullUrl = `${url}?${params}`;
  const response = await fetch(fullUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new RestError(
      response.status,
      fullUrl,
      'GET',
      undefined,
      await response.json(),
    );
  }
  return await response.json();
};
