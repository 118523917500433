import { cloneElement, FC, useMemo, useState } from 'react';

import { alpha, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  formatBytes,
  getMimeTypeFromExtension,
  MimeType,
  mimeTypeDetails,
  MimeTypeDetails,
} from 'utils/document/documentUtils';
import LinearProgress from '@mui/material/LinearProgress';

export type CardDocumentProps = {
  type?: string;
  name: string;
  size?: number;
  optionsMenu?: ('preview' | 'download' | 'delete' | 'update')[] | 'all';
  optionsInline?: ('preview' | 'download' | 'delete' | 'update')[] | 'all';
  isLoading?: boolean;
  onPreview?: () => void;
  onDownload?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
};

const CardDocument = (props: CardDocumentProps) => {
  const theme = useTheme();

  interface Option {
    label: string;
    icon?: FC;
    color?: string;
    isInlineButton?: boolean;
    onClick?: () => void;
  }

  const options: Record<string, Option> = {
    download: {
      label: 'Télécharger',
      icon: DownloadIcon,
      onClick: props.onDownload,
    },
    preview: {
      label: 'Afficher',
      icon: VisibilityIcon,
      onClick: props.onPreview,
    },
    update: {
      label: 'Modifier',
      isInlineButton: true,
      onClick: props.onUpdate,
    },
    delete: {
      label: 'Supprimer',
      icon: DeleteIcon,
      color: theme.palette.error.main,
      onClick: props.onDelete,
    },
  };

  const optionsMenu = props.optionsMenu || [];
  const optionsMenuElements = Object.entries(options)
    .filter(
      ([key, value]) =>
        (optionsMenu === 'all' ||
          optionsMenu.map(_ => _.toString()).includes(key)) &&
        value.onClick !== undefined,
    )
    .map(([key, value]) => {
      return (
        <>
          {key === 'delete' && <Divider />}

          <MenuItem
            key={key}
            onClick={() => {
              setAnchorOptionsMenu(null);
              if (value.onClick) value.onClick();
            }}
            sx={{
              color: value.color,
              ':hover': {
                background: value.color && alpha(value.color, 0.1),
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: value.color || theme.palette.text.primary,
              }}
            >
              {value.icon &&
                cloneElement(<value.icon />, { fontSize: 'small' })}
            </ListItemIcon>
            {value.label}
          </MenuItem>
        </>
      );
    });

  const optionsInline = props.optionsInline || 'all';
  const optionsInlineElements = Object.entries(options)
    .filter(
      ([key, value]) =>
        (optionsInline === 'all' ||
          optionsInline.map(_ => _.toString()).includes(key)) &&
        value.onClick !== undefined,
    )
    .sort(([keyA, valueA], [keyB, valueB]) => {
      if (valueA.isInlineButton === valueB.isInlineButton) {
        return 0;
      }
      return valueA.isInlineButton ? 1 : -1;
    })
    .map(([key, value]) => {
      if (value.icon === undefined || value.isInlineButton)
        return (
          <Button
            key={key}
            color="default"
            sx={{
              background: value.color || 'default',
            }}
            onClick={value.onClick}
          >
            {value.label}
          </Button>
        );
      else
        return (
          <Tooltip
            key={key}
            placement="top"
            title={value.label}
            componentsProps={{
              tooltip: {
                sx: {
                  color: value.color
                    ? theme.palette.error.main
                    : theme.palette.text.primary,
                },
              },
            }}
          >
            <IconButton
              onClick={value.onClick}
              sx={{
                ':hover': {
                  color: value.color || 'none',
                  background: value.color && alpha(value.color, 0.1),
                },
              }}
            >
              <value.icon />
            </IconButton>
          </Tooltip>
        );
    });

  const [anchorOptionsMenu, setAnchorOptionsMenu] =
    useState<null | HTMLElement>(null);

  const extension: string | undefined = props.name.split('.')?.pop();

  const mimeType = useMemo<MimeType | undefined>(() => {
    if (extension) return getMimeTypeFromExtension(extension);
  }, [extension]);
  const mimeTypeDetail = useMemo<MimeTypeDetails | undefined>(() => {
    if (mimeType) return mimeTypeDetails[mimeType];
  }, [mimeType]);

  return (
    <Card
      sx={{
        position: 'relative',
        p: 2,
      }}
    >
      {props.isLoading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            background: theme.palette.background.paper,
          }}
        />
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item sm xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  maxWidth: {
                    md: '800px',
                    sm: '400px',
                    xs: 'auto',
                  },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {props.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                divider={<Typography variant="caption">-</Typography>}
              >
                {mimeTypeDetail?.icon &&
                  cloneElement(mimeTypeDetail?.icon, {
                    sx: { width: 16, height: 16 },
                  })}
                <Typography
                  variant="caption"
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props?.size && formatBytes(props.size)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sm="auto"
          xs={12}
          sx={{
            direction: 'left',
          }}
        >
          <Stack
            direction={{
              sm: 'row',
              xs: 'row-reverse',
            }}
            justifyContent={{
              sm: 'flex-start',
              xs: 'flex-end',
            }}
            alignItems="center"
            spacing={1}
            useFlexGap
            sx={{ flexWrap: 'wrap' }}
          >
            {optionsInlineElements}

            {optionsMenuElements && optionsMenuElements.length > 0 && (
              <>
                <IconButton
                  onClick={e => setAnchorOptionsMenu(e.currentTarget)}
                  sx={{
                    position: {
                      sm: 'relative',
                      xs: 'absolute',
                    },
                    right: {
                      sm: 0,
                      xs: 8,
                    },
                    top: {
                      sm: 0,
                      xs: 8,
                    },
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorOptionsMenu}
                  open={anchorOptionsMenu !== null}
                  onClose={() => setAnchorOptionsMenu(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        minWidth: '300px',
                      },
                    },
                  }}
                >
                  {optionsMenuElements}
                </Menu>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

CardDocument.displayName = 'CardDocument';

export default CardDocument;
