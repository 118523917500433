import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {
  FormulasSelectionMap,
  ProjectContext,
  ProjectContextData,
} from 'components/Context/ProjectContext';
import MenuProject from 'components/Layout/LayoutProject/MenuProject';
import {
  getFirstStep,
  getStepFromPath,
  isStepDisabled,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { CommercialPropositionDialog } from 'pages/project/CommercialPropositionDialog';
import { EstimationDialog } from 'pages/project/EstimationDialog';
import { TransitionSlideUP } from 'utils/animations/transitions';
import { projectToFormulasSelectionMap } from 'utils/project/project';
import { ProjectStatus } from 'models/oav/ProjectStatus.models';

const LayoutProject: React.FC = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (!id) return null;

  const theme = useTheme();
  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));
  const [estimationDialogOpen, setEstimationDialogOpen] = useState(false);
  const [commercialPropositionDialogOpen, setCommercialPropositionDialogOpen] =
    useState(false);

  const [dataCurrent, setDataCurrent] = useState<ProjectContextData>({
    project: undefined,
  });

  // Get project.
  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const persistedSelectedFormulas = useMemo<FormulasSelectionMap>(
    () => projectToFormulasSelectionMap(projectQuery.data),
    [projectQuery.data],
  );

  const [selectedFormulas, setSelectedFormulas] =
    useState<FormulasSelectionMap>(persistedSelectedFormulas);

  useEffect(() => {
    setSelectedFormulas(persistedSelectedFormulas);
  }, [persistedSelectedFormulas, pathname]);

  const resetSelectedFormulas = useCallback(() => setSelectedFormulas({}), []);

  const projectOutOfEdition =
    projectQuery.data?.status == ProjectStatus.SIGNATURE_DONE ||
    projectQuery.data?.status == ProjectStatus.MANAGEMENT_DONE ||
    projectQuery.data?.status == ProjectStatus.MANAGEMENT_FAILED ||
    projectQuery.data?.status == ProjectStatus.SIGNATURE_IN_PROGRESS ||
    projectQuery.data?.status == ProjectStatus.EXPIRED ||
    projectQuery.data?.status == ProjectStatus.CANCELED;

  // Check ProjectStep on path change.
  useEffect(() => {
    if (!projectQuery.data) return;

    const currentStep = getStepFromPath(pathname);

    // Redirect to /project/{uuid} if project not editable
    if (projectOutOfEdition) {
      !pathname.endsWith(`/projets/${projectQuery.data?.id}`) &&
        navigate(`/projets/${projectQuery.data?.id}`, { replace: true });
      return;
    }

    // Navigate to current step if no step found from path
    if (!currentStep) {
      if (projectQuery.data.currentStep) {
        navigate(projectStepsData[projectQuery.data.currentStep]!.path, {
          replace: true,
        });
      }
      return;
    }

    // If no step in the projet redirect to first step
    if (!projectQuery.data.currentStep) {
      if (currentStep !== getFirstStep())
        navigate(projectStepsData[getFirstStep()]!.path);
      return;
    }

    // Redirect to current step if path step is disabled
    if (isStepDisabled(currentStep, projectQuery.data.currentStep)) {
      navigate(projectStepsData[projectQuery.data.currentStep]!.path);
    }
  }, [pathname, projectQuery.data, projectOutOfEdition]);

  useEffect(() => {
    setDataCurrent(prev => ({ ...prev, project: projectQuery.data }));
  }, [pathname, projectQuery.data]);

  return (
    <ProjectContext.Provider
      value={{
        setSelectedFormulas,
        selectedFormulas,
        resetSelectedFormulas,
        estimationDialogOpen,
        closeEstimationDialog: () => setEstimationDialogOpen(false),
        openEstimationDialog: () => setEstimationDialogOpen(true),
        commercialPropositionDialogOpen,
        closeCommercialPropositionDialog: () =>
          setCommercialPropositionDialogOpen(false),
        openCommercialPropositionDialog: () =>
          setCommercialPropositionDialogOpen(true),
        dataCurrent,
        dataPersisted: dataCurrent,
        setDataCurrent,
        setDataPersisted: setDataCurrent,
      }}
    >
      <Dialog
        open={estimationDialogOpen}
        onClose={() => setEstimationDialogOpen(false)}
        maxWidth="md"
        fullScreen={!screenSizeUpSM}
        TransitionComponent={TransitionSlideUP}
      >
        <EstimationDialog />
      </Dialog>
      <Dialog
        open={commercialPropositionDialogOpen}
        onClose={() => setCommercialPropositionDialogOpen(false)}
        maxWidth="md"
        fullScreen={!screenSizeUpSM}
        TransitionComponent={TransitionSlideUP}
      >
        <CommercialPropositionDialog />
      </Dialog>
      <Grid container spacing={2}>
        <Grid item md xs={12}>
          <Outlet />
        </Grid>

        <Grid
          item
          md="auto"
          xs={12}
          sx={{
            mt: 5,
            display: {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 24,
              width: {
                lg: '250px',
                md: '200px',
              },
            }}
          >
            {projectQuery.isError || offersMapQuery.isError ? (
              <></>
            ) : projectQuery.isLoading ||
              !projectQuery.data ||
              offersMapQuery.isLoading ||
              !offersMapQuery.data ? (
              <Skeleton variant="rectangular" animation="wave" height="200px" />
            ) : (
              <MenuProject
                project={projectQuery.data}
                offersMap={offersMapQuery.data}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </ProjectContext.Provider>
  );
};

export default LayoutProject;
