import { Subscription } from 'models/oav/Subscription.models';
import { Offer } from 'models/referentiels/Offer.model';

export const getSegmentLabelsBySubscriptions = (
  subscriptions: Subscription[] | undefined,
  offers: Map<string, Offer> | undefined,
  noValueLabel = 'Non renseigné',
): string => {
  if (subscriptions == null || offers == null) {
    return noValueLabel;
  }

  const segmentLabels = subscriptions
    .flatMap(s =>
      offers!.has(s.offerCode)
        ? offers!.get(s.offerCode)!.segments!.map(s => s.label)
        : [],
    )
    .filter(s => s.length != 0);
  const removedDuplicateSegmentLabels = Array.from(
    new Set(segmentLabels).values(),
  );
  return removedDuplicateSegmentLabels.join(', ');
};
