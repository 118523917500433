import { useTheme } from '@mui/material/styles';
import { Icon } from '.';
import { IconProps } from './settings';

export const UploadIcon = (props: IconProps) => {
  const theme = useTheme();
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={80}
        height={80}
        fill="none"
      >
        <circle cx={42} cy={39.999} r={29.333} fill={theme.palette.divider} />
        <g filter="url(#c)">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M13.912 30.491c1.152-8.91 9.577-15.825 19.798-15.825 7.361 0 13.79 3.587 17.243 8.922a12.236 12.236 0 0 1 4.984-1.06c5.161 0 9.64 3.231 11.87 7.963h.01c7.097 0 12.85 5.753 12.85 12.85 0 7.096-5.753 12.849-12.85 12.849H13.517c-7.097 0-12.85-5.753-12.85-12.85 0-7.096 5.753-12.849 12.85-12.849h.396Z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#d)">
          <path
            fill={props.color}
            fillRule="evenodd"
            d="M29.382 42.545c-.65.61-.65 1.6 0 2.21.65.61 1.703.61 2.353 0l8.814-8.681v26.349c0 1.239.776 2.243 1.733 2.243.958 0 1.734-1.004 1.734-2.243V35.56l9.794 9.193c.65.61 1.703.61 2.353 0 .65-.61.65-1.599 0-2.209L43.95 31.081a1.742 1.742 0 0 0-2.353 0L29.382 42.545Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <filter
            id="c"
            width={80}
            height={41.523}
            x={0.667}
            y={14.666}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={7} />
            <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
            <feColorMatrix values="0 0 0 0 0.199268 0 0 0 0 0.250873 0 0 0 0 0.379887 0 0 0 1 0" />
            <feBlend in2="shape" result="effect1_innerShadow_2031_21951" />
          </filter>
          <filter
            id="d"
            width={47.756}
            height={54.043}
            x={18.895}
            y={20.623}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={5} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0.752941 0 0 0 0 0.772549 0 0 0 0 0.831373 0 0 0 1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2031_21951"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_2031_21951"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Icon>
  );
};
