import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';
import { fetchRisks, fetchSegments } from 'api/referentiels';
import { fetchRegimes } from 'api/referentiels/Regime.api';

const useAccessToken = (): string => {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;

  if (!accessToken) {
    throw new Error(
      'Fetch could not be performed, secured endpoint requested without being authenticated.',
    );
  }
  return accessToken;
};

export const useSegments = () => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['segments'],
    queryFn: () => fetchSegments(accessToken),
  });
};

export const useRisks = () => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['risks'],
    queryFn: () => fetchRisks(accessToken),
  });
};

export const useRegimes = () => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['regimes'],
    queryFn: () => fetchRegimes(accessToken),
    staleTime: 300000, // 5min
    gcTime: 300000, // 5min
  });
};
