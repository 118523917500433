import { ReactComponentLike } from 'prop-types';
import { NeedCureIcon } from 'components/Icon/NeedCureIcon';
import { NeedDentalIcon } from 'components/Icon/NeedDentalIcon';
import { NeedHospitalIcon } from 'components/Icon/NeedHospitalIcon';
import { NeedOpticalIcon } from 'components/Icon/NeedOpticalIcon';
import { NeedAudioIcon } from 'components/Icon/NeedAudioIcon';
import { NeedRoutineCareIcon } from 'components/Icon/NeedRoutineCareIcon';
import { NeedSoftMedicineIcon } from 'components/Icon/NeedSoftMedicineIcon';

export interface OfferNeed {
  id: string;
  needType: OfferNeedType;
  label: string;
  needOrder: number;
}

export enum OfferNeedType {
  HOSPITALISATION = 'HOSPITALISATION',
  SOINS_COURANTS = 'SOINS_COURANTS',
  OPTIQUE = 'OPTIQUE',
  DENTAIRE = 'DENTAIRE',
  MEDECINE_DOUCE = 'MEDECINE_DOUCE',
  PROTHESE_AUDITIVE = 'PROTHESE_AUDITIVE',
  CURE = 'CURE',
}

export enum OfferNeedWeight {
  'PAS_DE_BESOIN' = 0,
  'BASIQUE' = 1,
  'EQUILIBRE' = 3,
  'CONFORTABLE' = 5,
}

export const offerNeedWeightLabels: {
  [key in OfferNeedWeight]: { label: string };
} = {
  [OfferNeedWeight.PAS_DE_BESOIN]: { label: 'Pas de besoin' },
  [OfferNeedWeight.BASIQUE]: { label: 'Basique' },
  [OfferNeedWeight.EQUILIBRE]: { label: 'Equilibré' },
  [OfferNeedWeight.CONFORTABLE]: { label: 'Confortable' },
};

export const offerNeedDatas: {
  [key in OfferNeedType]: { icon: ReactComponentLike; label: string };
} = {
  [OfferNeedType.CURE]: {
    icon: NeedCureIcon,
    label: 'Cure',
  },
  [OfferNeedType.DENTAIRE]: {
    icon: NeedDentalIcon,
    label: 'Dentaire',
  },
  [OfferNeedType.HOSPITALISATION]: {
    icon: NeedHospitalIcon,
    label: 'Hospitalisation',
  },
  [OfferNeedType.OPTIQUE]: {
    icon: NeedOpticalIcon,
    label: 'Optique',
  },
  [OfferNeedType.PROTHESE_AUDITIVE]: {
    icon: NeedAudioIcon,
    label: 'Auditif',
  },
  [OfferNeedType.SOINS_COURANTS]: {
    icon: NeedRoutineCareIcon,
    label: 'Soins courants',
  },
  [OfferNeedType.MEDECINE_DOUCE]: {
    icon: NeedSoftMedicineIcon,
    label: 'Prévention & médecine douce',
  },
};
