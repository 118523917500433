import React from 'react';
import { getIn, useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from 'components/TextField/TextField';
import { AddressFormValues } from 'models/Form.model';
import { postalCodeSize } from 'utils/validation/yupPerson';

const addressLine1Field = 'address.addressLine1';
const addressLine2Field = 'address.addressLine2';
const addressLine3Field = 'address.addressLine3';
const postalCodeField = 'address.postalCode';
const cityField = 'address.city';

export const AddressFrom = () => {
  const { values, errors, touched, handleChange, setFieldValue, handleBlur } =
    useFormikContext<{ address: AddressFormValues }>();

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id={addressLine1Field}
          name={addressLine1Field}
          label="N° et nom de voie"
          value={getIn(values, addressLine1Field)}
          error={
            getIn(touched, addressLine1Field) &&
            !!getIn(errors, addressLine1Field)
          }
          helperText={
            getIn(touched, addressLine1Field) &&
            getIn(errors, addressLine1Field)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id={addressLine2Field}
          name={addressLine2Field}
          label="Complément d'adresse 1"
          value={getIn(values, addressLine2Field)}
          error={
            getIn(touched, addressLine2Field) &&
            !!getIn(errors, addressLine2Field)
          }
          helperText={
            getIn(touched, addressLine2Field) &&
            getIn(errors, addressLine2Field)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id={addressLine3Field}
          name={addressLine3Field}
          label="Complément d'adresse 2"
          value={getIn(values, addressLine3Field)}
          error={
            getIn(touched, addressLine3Field) &&
            !!getIn(errors, addressLine3Field)
          }
          helperText={
            getIn(touched, addressLine3Field) &&
            getIn(errors, addressLine3Field)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id={postalCodeField}
          name={postalCodeField}
          label="Code postal"
          value={getIn(values, postalCodeField)}
          error={
            getIn(touched, postalCodeField) && !!getIn(errors, postalCodeField)
          }
          helperText={
            getIn(touched, postalCodeField) && getIn(errors, postalCodeField)
          }
          onChange={e =>
            setFieldValue(postalCodeField, e.target.value.replaceAll(/\D/g, ''))
          }
          onBlur={handleBlur}
          inputProps={{ minLength: postalCodeSize, maxLength: postalCodeSize }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id={cityField}
          name={cityField}
          label="Ville"
          value={getIn(values, cityField)}
          error={getIn(touched, cityField) && !!getIn(errors, cityField)}
          helperText={getIn(touched, cityField) && getIn(errors, cityField)}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
    </>
  );
};
