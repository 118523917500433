import { Fragment, memo, useMemo } from 'react';

import { alpha, useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import WarrantyFormulaAmounts from 'content/warranty/WarrantyFormulaAmounts';

import {
  WarrantyAcronym,
  WarrantyAcronymMap,
  WarrantyAmount,
  WarrantySubSection,
} from 'models/referentiels/Warranty.model';
import { Formula } from 'models/referentiels/Formula.model';

export type WarrantySubSectionTableRowsProps = {
  subSection: WarrantySubSection;
  nbFormula: number;
  formulas: Formula[];
  formulasSelected?: string[];
  labelOnLeftColumn?: boolean;
  acronymsMap?: WarrantyAcronymMap;
};

export const WarrantySubSectionTableRows = (
  props: WarrantySubSectionTableRowsProps,
) => {
  const theme = useTheme();

  const warranties = useMemo(() => {
    return props.subSection.warranties.sort((a, b) => a.order - b.order);
  }, [props.subSection]);

  return (
    <>
      <TableRow>
        <TableCell
          colSpan={props.nbFormula + (props.labelOnLeftColumn ? 2 : 0)}
          sx={{
            textAlign: props.labelOnLeftColumn ? 'left' : 'right',
            pt: 2,
          }}
        >
          <Typography>{props.subSection.subSectionTitle}</Typography>
        </TableCell>
      </TableRow>

      {warranties.map((warranty, warrantyIdx) => {
        const labelCell = (
          <TableCell
            align={
              props.labelOnLeftColumn || props.nbFormula === 1
                ? 'right'
                : 'left'
            }
            colSpan={props.labelOnLeftColumn ? 2 : props.nbFormula}
            sx={{
              py: 0.5,
            }}
          >
            <Typography variant="body2" fontSize="0.9rem">
              {warranty.label}
            </Typography>
          </TableCell>
        );

        return (
          <Fragment key={warrantyIdx}>
            {!props.labelOnLeftColumn && <TableRow>{labelCell}</TableRow>}

            <TableRow>
              {props.labelOnLeftColumn && labelCell}

              {props.formulas.map((f, idx) => {
                const selected =
                  props.formulasSelected &&
                  props.formulasSelected.includes(f.code);

                const formulaWarranty = warranty.formulas.find(
                  fw => fw.formulaCode === f.code,
                );

                return (
                  <WarrantyTableCell
                    key={f.code}
                    idx={idx}
                    nbFormula={props.nbFormula}
                    selected={!!selected}
                    amounts={formulaWarranty?.amounts}
                    acronymsMap={props.acronymsMap}
                    isLast={warrantyIdx == warranties.length - 1}
                    textAlign={
                      !props.labelOnLeftColumn && props.nbFormula === 1
                        ? 'right'
                        : 'center'
                    }
                    theme={theme}
                  />
                );
              })}
            </TableRow>
          </Fragment>
        );
      })}
    </>
  );
};

const WarrantyTableCell = memo(
  ({
    idx,
    theme,
    selected,
    amounts,
    acronymsMap,
    nbFormula,
    isLast,
    textAlign,
  }: {
    idx: number;
    theme: Theme;
    selected: boolean;
    amounts: WarrantyAmount[] | undefined;
    acronymsMap: Record<string, WarrantyAcronym> | undefined;
    nbFormula: number;
    isLast?: boolean;
    textAlign?: 'left' | 'center' | 'right';
  }) => (
    <TableCell
      align={nbFormula === 1 ? 'right' : 'center'}
      sx={{
        py: 1,
        maxWidth: '1px',
        overflow: 'hidden',
        width: `calc(100% / (${nbFormula} + 2))`,
        borderLeft: idx === 0 ? 0 : 2,
        borderRight: idx + 1 === nbFormula ? 0 : 2,
        borderBottom: nbFormula === 1 && !isLast ? 1 : 0,
        borderColor: theme.palette.divider,
        background: selected ? alpha(theme.palette.primary.main, 0.1) : 'none',
      }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <WarrantyFormulaAmounts
          textAlign={textAlign}
          formulaWarrantyAmounts={amounts}
          acronymsMap={acronymsMap}
        />
      </Typography>
    </TableCell>
  ),
);
WarrantyTableCell.displayName = 'WarrantyTableCell';
