import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FormBeneficiaryExpress, {
  badRequestToFormBeneficiaryExpressErrors,
  FormBeneficiaryExpressErrors,
  FormBeneficiaryExpressProps,
  FormBeneficiaryExpressRef,
  FormBeneficiaryExpressValues,
  formBeneficiaryExpressValuesToDto,
} from 'content/form/FormBeneficiaryExpress';
import FormBeneficiaryExpressCard from 'content/form/FormBeneficiaryExpressCard';
import {
  Project,
  ProjectRequestPost,
  ProjectRequestPostErrors,
} from 'models/oav/Project.models';
import { BeneficiaryType } from 'models/oav/Beneficiary.models';
import { createProject } from 'api/oav/Project.api';
import { isBadRequestWithDetailsError } from 'utils/api/api';

export interface FormBeneficiariesExpressErrors {
  subscriber?: FormBeneficiaryExpressErrors;
  partner?: FormBeneficiaryExpressErrors;
  children?: FormBeneficiaryExpressErrors[];
}

export interface FormBeneficiariesExpressValues {
  subscriber?: FormBeneficiaryExpressValues;
  partner?: FormBeneficiaryExpressValues;
  children?: FormBeneficiaryExpressValues[];
}

export interface FormBeneficiariesExpressRef {
  submitForm: () => Promise<Project>;
  validateForm: () => Promise<FormBeneficiariesExpressErrors>;
  resetForm: () => void;
  values: FormBeneficiariesExpressValues;
}

export interface FormBeneficiariesExpressProps {
  initialValues?: FormBeneficiariesExpressValues;
  hideSubscriber?: boolean;
  hidePartner?: boolean;
  hideChildren?: boolean;
  maxBeneficiaries: number;
  onChangeValues?: (_: FormBeneficiariesExpressValues) => void;
}

const FormBeneficiariesExpress = forwardRef(
  (props: FormBeneficiariesExpressProps, ref: any) => {
    useImperativeHandle(ref, () => ({
      submitForm: () => {
        return submit();
      },
      validateForm: () => {
        return validate();
      },
      resetForm: () => {
        return reset();
      },
      get values() {
        return getValues();
      },
    }));

    type formItem = {
      key: string;
      ref: FormBeneficiaryExpressRef | null;
      props: FormBeneficiaryExpressProps;
      errors?: FormBeneficiaryExpressErrors;
    };

    const auth = useAuth();

    // Subscriber
    const [subscriberSelected, setSubscriberSelected] = useState<boolean>(
      !props.hideSubscriber,
    );
    const [formSubscriber, setFormSubscriber] = useState<formItem>({
      key: uuidv4(),
      ref: null,
      props: {
        type: BeneficiaryType.SUBSCRIBER,
        initialValues: props.initialValues?.subscriber,
      },
    });

    useEffect(() => {
      if (props.initialValues) return;
      if (!subscriberSelected) formSubscriber.ref?.resetForm();
    }, [subscriberSelected]);

    // Partner
    const [partnerSelected, setPartnerSelected] = useState<boolean>(
      !props.hidePartner && props.initialValues?.partner !== undefined,
    );
    const [formPartner, setFormPartner] = useState<formItem>({
      key: uuidv4(),
      ref: null,
      props: {
        type: BeneficiaryType.PARTNER,
        initialValues: props.initialValues?.partner,
      },
    });

    useEffect(() => {
      if (props.initialValues) return;
      if (!partnerSelected) formPartner.ref?.resetForm();
    }, [partnerSelected]);

    // Children
    const [childrenSelected, setChildrenSelected] = useState<boolean>(
      !props.hideChildren && props.initialValues?.children !== undefined,
    );
    const [childrenDeleteDialogIndex, setChildrenDeleteDialogIndex] =
      useState<string>();
    const [formChildren, setFormChildren] = useState<formItem[]>(
      (props.initialValues?.children || [{ type: BeneficiaryType.CHILD }]).map(
        _ => ({
          key: uuidv4(),
          ref: null,
          props: {
            type: BeneficiaryType.CHILD,
            initialValues: _,
          },
        }),
      ),
    );

    const addChild = () => {
      setFormChildren(prev => [
        ...prev,
        {
          key: uuidv4(),
          ref: null,
          props: {
            type: BeneficiaryType.CHILD,
          },
        },
      ]);
    };

    const deleteChild = (key: string) => {
      setFormChildren(prev => prev.filter(_ => _.key !== key));
    };

    useEffect(() => {
      if (props.initialValues) return;
      if (!childrenSelected) formChildren.forEach(_ => _.ref?.resetForm());
    }, [childrenSelected]);

    // Main form
    const submit = async () => {
      const errors = await validate();
      const values = getValues();

      if (Object.keys(errors).length !== 0) return;

      // Build DTO.
      const payload: ProjectRequestPost = {
        subscriber: formBeneficiaryExpressValuesToDto(
          values.subscriber as FormBeneficiaryExpressValues,
        ),
        partner:
          values.partner && formBeneficiaryExpressValuesToDto(values.partner),
        children:
          values.children &&
          values.children.map(_ => formBeneficiaryExpressValuesToDto(_)),
      };

      if (props.initialValues?.subscriber?.id) {
        alert('update project');
      } else {
        return createProject(auth.user!.access_token, payload).catch(e => {
          if (isBadRequestWithDetailsError<ProjectRequestPostErrors>(e)) {
            const errors = e.response?.details;
            if (errors) onBadRequest(errors);
          } else {
            // TODO : Snackbar
          }
        });
      }
    };

    const onBadRequest = (errors: ProjectRequestPostErrors) => {
      if (errors.subscriber) {
        const er = errors.subscriber;
        setFormSubscriber(prev => ({
          ...prev,
          errors: badRequestToFormBeneficiaryExpressErrors(er),
        }));
      }

      if (errors.partner) {
        const er = errors.partner;
        setFormPartner(prev => ({
          ...prev,
          errors: badRequestToFormBeneficiaryExpressErrors(er),
        }));
      }

      if (formChildren) {
        formChildren.map((_, index) => {
          const er = errors[
            `children[${index}]`
          ] as FormBeneficiaryExpressErrors;
          if (er) {
            setFormChildren(prev => {
              const f: formItem[] = [...prev];
              f[index] = {
                ...f[index],
                errors: badRequestToFormBeneficiaryExpressErrors(er),
              };
              return f;
            });
          }
        });
      }
    };

    const validate = async () => {
      const errors: FormBeneficiariesExpressErrors = {};
      if (subscriberSelected && formSubscriber.ref) {
        const e = await formSubscriber.ref?.validateForm();
        if (Object.keys(e).length > 0) errors.subscriber = e;
      }

      if (partnerSelected && formPartner.ref) {
        const e = await formPartner.ref?.validateForm();
        if (Object.keys(e).length > 0) errors.partner = e;
      }

      if (childrenSelected && formChildren.length > 0) {
        const children: FormBeneficiaryExpressErrors[] = [];
        for (const _ of formChildren) {
          const e = await _.ref?.validateForm();
          children?.push(Object.keys(e).length > 0 ? e : undefined);
        }
        if (children.filter(_ => _ !== undefined).length > 0)
          errors.children = children;
      }

      return errors;
    };

    const reset = () => {
      const errors: FormBeneficiariesExpressErrors = {};
      if (subscriberSelected && formSubscriber.ref) {
        formSubscriber.ref.resetForm();
      }

      if (partnerSelected && formPartner.ref) {
        formPartner.ref.resetForm();
      }

      if (childrenSelected && formChildren.length > 0) {
        for (const _ of formChildren) {
          _.ref?.resetForm();
        }
      }

      return errors;
    };

    const getValues = (): FormBeneficiariesExpressValues => {
      const values: FormBeneficiariesExpressValues = {};
      if (subscriberSelected && formSubscriber.ref) {
        values.subscriber = formSubscriber.ref?.values;
      }

      if (partnerSelected && formPartner.ref) {
        values.partner = formPartner.ref?.values;
      }

      if (childrenSelected && formChildren.length > 0) {
        values.children = [];
        for (const _ of formChildren) {
          const v = _.ref?.values;
          if (v === undefined) continue;
          values.children?.push(v);
        }
      }

      return values;
    };

    const onChange = () => {
      if (props.onChangeValues) props.onChangeValues(getValues());
    };

    useEffect(() => {
      onChange();
    }, [subscriberSelected, partnerSelected, childrenSelected]);

    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const elementId = location.hash.substring(1);
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }, [location]);

    const maxChildren = partnerSelected
      ? props.maxBeneficiaries - 2
      : props.maxBeneficiaries - 1;

    formChildren.splice(maxChildren, 1);
    return (
      <Grid container justifyContent="center" spacing={2}>
        {!props.hideSubscriber && (
          <Grid item xs={12} id="souscripteur">
            <FormBeneficiaryExpressCard
              type={BeneficiaryType.SUBSCRIBER}
              selected={subscriberSelected}
            >
              <FormBeneficiaryExpress
                {...formSubscriber.props}
                ref={(el: FormBeneficiaryExpressRef | null) =>
                  (formSubscriber.ref = el)
                }
                initialValues={formSubscriber.props.initialValues}
                onChangeValues={_ => onChange()}
                errors={formSubscriber.errors}
              />
            </FormBeneficiaryExpressCard>
          </Grid>
        )}

        {!props.hidePartner && (
          <Grid item xs={12} id="conjoint">
            <FormBeneficiaryExpressCard
              type={BeneficiaryType.PARTNER}
              selected={partnerSelected}
              onClick={_ => setPartnerSelected(_)}
              disabled={
                !partnerSelected &&
                childrenSelected &&
                formChildren.length >= maxChildren
              }
            >
              <FormBeneficiaryExpress
                {...formPartner.props}
                ref={(el: FormBeneficiaryExpressRef | null) =>
                  (formPartner.ref = el)
                }
                initialValues={formPartner.props.initialValues}
                onChangeValues={_ => onChange()}
                errors={formPartner.errors}
              />
            </FormBeneficiaryExpressCard>
          </Grid>
        )}

        {!props.hideChildren && (
          <Grid item xs={12} id="enfant(s)">
            <FormBeneficiaryExpressCard
              type={BeneficiaryType.CHILD}
              count={formChildren.length}
              selected={childrenSelected}
              onClick={_ => setChildrenSelected(_)}
            >
              <Grid container spacing={2}>
                {formChildren.map((_, index) => (
                  <Grid item xs={12} key={_.key}>
                    <Stack spacing={2} id={`enfant${index + 1}`}>
                      <Typography>
                        Enfant n° {index + 1}
                        {formChildren.length > 1 && (
                          <>
                            &nbsp;-&nbsp;
                            <Link
                              onClick={() =>
                                setChildrenDeleteDialogIndex(_.key)
                              }
                            >
                              Supprimer
                            </Link>
                          </>
                        )}
                      </Typography>
                      <FormBeneficiaryExpress
                        {..._.props}
                        ref={(el: FormBeneficiaryExpressRef | null) =>
                          (_.ref = el)
                        }
                        initialValues={
                          props.initialValues?.children &&
                          props.initialValues.children[index]
                        }
                        onChangeValues={_ => onChange()}
                        errors={_.errors}
                      />
                    </Stack>
                  </Grid>
                ))}

                {(maxChildren === undefined ||
                  maxChildren > formChildren.length) && (
                  <Grid item xs={12}>
                    <Button onClick={addChild} color="dark">
                      Ajouter un enfant
                    </Button>
                  </Grid>
                )}
              </Grid>
            </FormBeneficiaryExpressCard>

            <Dialog
              open={childrenDeleteDialogIndex !== undefined}
              fullWidth
              maxWidth="sm"
            >
              {childrenDeleteDialogIndex !== undefined && (
                <>
                  <DialogTitle>
                    <Typography variant="h2">
                      Supprimer l'enfant n°{' '}
                      {formChildren.findIndex(
                        _ => _.key === childrenDeleteDialogIndex,
                      ) + 1}{' '}
                      ?
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">
                      Toute suppression est définitive
                    </Typography>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      p: 2,
                    }}
                  >
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid
                        order={{
                          sm: 0,
                          xs: 1,
                        }}
                        item
                        sm="auto"
                        xs={12}
                      >
                        <Button
                          fullWidth
                          variant="text"
                          color="default"
                          onClick={() =>
                            setChildrenDeleteDialogIndex(undefined)
                          }
                        >
                          Annuler
                        </Button>
                      </Grid>
                      <Grid
                        order={{
                          sm: 1,
                          xs: 0,
                        }}
                        item
                        sm="auto"
                        xs={12}
                      >
                        <Button
                          fullWidth
                          autoFocus
                          color="error"
                          onClick={() => {
                            if (childrenDeleteDialogIndex)
                              deleteChild(childrenDeleteDialogIndex);
                            setChildrenDeleteDialogIndex(undefined);
                          }}
                        >
                          Supprimer
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Grid>
        )}
      </Grid>
    );
  },
);

export default FormBeneficiariesExpress;
