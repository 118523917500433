import { Icon } from '.';
import { IconProps } from './settings';

export const DocumentIDIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'80'}
        height={'80'}
        fill="none"
        viewBox="0 0 80 80"
      >
        <rect
          width="66.3"
          height="41"
          x="6.85"
          y="19.5"
          stroke={props.color}
          rx="3.5"
        ></rect>
        <mask id="path-2-inside-1_2031_18964" fill="#fff">
          <path
            fillRule="evenodd"
            d="M24.786 39.724c3.657 0 6.622-2.849 6.622-6.362 0-3.514-2.965-6.362-6.622-6.362-3.658 0-6.623 2.848-6.623 6.362 0 3.513 2.965 6.362 6.623 6.362zm-.036.552c-5.744 0-10.4 4.656-10.4 10.4V53h20.8v-2.324c0-5.744-4.656-10.4-10.4-10.4z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill={props.color}
          d="M14.35 53h-1v1h1v-1zm20.8 0v1h1v-1h-1zm-4.742-19.638c0 2.924-2.479 5.362-5.622 5.362v2c4.171 0 7.622-3.259 7.622-7.362h-2zM24.786 28c3.143 0 5.622 2.438 5.622 5.362h2c0-4.104-3.45-7.362-7.622-7.362v2zm-5.623 5.362c0-2.924 2.479-5.362 5.623-5.362v-2c-4.172 0-7.623 3.259-7.623 7.362h2zm5.623 5.362c-3.144 0-5.623-2.438-5.623-5.362h-2c0 4.103 3.45 7.362 7.623 7.362v-2zM15.35 50.676a9.4 9.4 0 019.4-9.4v-2c-6.296 0-11.4 5.104-11.4 11.4h2zm0 2.324v-2.324h-2V53h2zm19.8-1h-20.8v2h20.8v-2zm-1-1.324V53h2v-2.324h-2zm-9.4-9.4a9.4 9.4 0 019.4 9.4h2c0-6.296-5.104-11.4-11.4-11.4v2z"
          mask="url(#path-2-inside-1_2031_18964)"
        ></path>
        <path
          stroke={props.color}
          strokeLinecap="round"
          d="M41.15 32h24.5M41.15 40h24.5M41.15 48h24.5"
        ></path>
      </svg>
    </Icon>
  );
};
