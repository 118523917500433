import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import ProtectedApp from 'components/ProtectedApp';
import LayoutContext from 'components/Layout/LayoutContent';
import LayoutApp from 'components/Layout/LayoutApp';
import DashboardPage from 'pages/dashboard/DashboardPage';
import PersonIndexPage from 'pages/person/PersonIndexPage';
import AccountMePage from 'pages/account/me';
import AccountCompanyPage from 'pages/account/company';
import AccountProtocolPage from 'pages/account/protocol';
import AccountUserPage from 'pages/account/user';
import AccountMeLayout from 'pages/account/layout';
import LayoutProject from 'components/Layout/LayoutProject/LayoutProject';
import ProjectsPage from 'pages/project/ProjectsPage';
import WalletPage from 'pages/wallet/WalletPage';
import ContractPage from 'pages/wallet/{code}/ContractPage';
import ProjectNewPage from 'pages/project/new/ProjectNewPage';
import ProjectBeneficiariesPage from 'pages/project/{uuid}/edition/ProjectBeneficiariesPage';

import NotFoundPage from 'pages/NotFound';
import OffersPage from 'pages/offer/OffersPage';
import OfferPage from 'pages/offer/{code}/OfferPage';
import ProjectOffersPage from 'pages/project/{uuid}/edition/ProjectOffersPage';
import ProjectNeedsPage from 'pages/project/{uuid}/edition/ProjectNeedsPage';
import { AppProvider } from 'components/Context/AppContext';
import PersonNewPage from 'pages/person/nouveau/PersonNewPage';
import PersonPage from 'pages/person/{uuid}/PersonPage';
import { LayoutPerson } from 'components/Layout/LayoutPerson/LayoutPerson';
import ProjectContactInformationsPage from 'pages/project/{uuid}/edition/ProjectContactInformationsPage';
import { SnackbarProvider, useSnackbar, VariantType } from 'notistack';
import { forwardRef, ReactNode } from 'react';
import { Alert } from '@mui/material';
import ProjectProposalPage from 'pages/project/{uuid}/edition/ProjectProposalPage';
import ProjectClientsInformationPage from 'pages/project/{uuid}/edition/ProjectClientsInformationPage';
import ProjectAddressDetailsPage from 'pages/project/{uuid}/edition/ProjectAddressDetailsPage';
import ProjectFormulaChoicePage from 'pages/project/{uuid}/edition/ProjectFormulaChoicePage';
import WalletCommissionsPage from 'pages/wallet/commissions/WalletCommissionsPage';
import ProjectContractPage from 'pages/project/{uuid}/edition/ProjectContractPage';
import ProjectSignaturePage from 'pages/project/{uuid}/edition/ProjectSignaturePage';
import ProjectPage from 'pages/project/{uuid}/ProjectPage';
import LayoutProjectEdit from 'components/Layout/LayoutProject/LayoutProjectEdit';

export const appName = 'Clariss';

const SnackComponent = forwardRef<
  never,
  { id: number; message: ReactNode; variant: VariantType }
>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Alert
      ref={ref}
      severity={props.variant === 'default' ? 'info' : props.variant}
      variant="filled"
      onClose={() => closeSnackbar(props.id)}
    >
      {props.message}
    </Alert>
  );
});

SnackComponent.displayName = 'SnackComponent';
function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      Components={{
        default: SnackComponent,
        error: SnackComponent,
        info: SnackComponent,
        success: SnackComponent,
        warning: SnackComponent,
      }}
    >
      <AppProvider>
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <Route element={<LayoutContext />}>
                <Route element={<ProtectedApp />}>
                  <Route element={<LayoutApp />}>
                    <Route index element={<DashboardPage />} />
                    <Route path="compte">
                      <Route element={<AccountMeLayout />}>
                        <Route index element={<AccountMePage />} />
                        <Route
                          path="entreprise"
                          element={<AccountCompanyPage />}
                        />
                        <Route
                          path="protocole"
                          element={<AccountProtocolPage />}
                        />
                        <Route
                          path="utilisateur"
                          element={<AccountUserPage />}
                        />
                      </Route>
                    </Route>
                    <Route path="dashboard">
                      <Route index element={<DashboardPage />} />
                    </Route>
                    <Route path="offres">
                      <Route index element={<OffersPage />} />
                      <Route path=":code" element={<OfferPage />} />
                    </Route>
                    <Route path="projets">
                      <Route index element={<ProjectsPage />} />
                      <Route path="nouveau" element={<ProjectNewPage />} />
                      <Route path=":id" element={<LayoutProject />}>
                        <Route index element={<ProjectPage />} />
                        <Route path="edition" element={<LayoutProjectEdit />}>
                          <Route
                            path="offres"
                            element={<ProjectOffersPage />}
                          />
                          <Route
                            path="contact"
                            element={<ProjectContactInformationsPage />}
                          />
                          <Route
                            path="besoins"
                            element={<ProjectNeedsPage />}
                          />
                          <Route
                            path="proposition"
                            element={<ProjectProposalPage />}
                          />
                          <Route
                            path="beneficiaires"
                            element={<ProjectBeneficiariesPage />}
                          />
                          <Route
                            path="donnees-clients"
                            element={<ProjectClientsInformationPage />}
                          />
                          <Route
                            path="coordonnees-postales"
                            element={<ProjectAddressDetailsPage />}
                          />
                          <Route
                            path="choix-de-la-couverture"
                            element={<ProjectFormulaChoicePage />}
                          />
                          <Route
                            path="contractualisation"
                            element={<ProjectContractPage />}
                          />
                          <Route
                            path="signature"
                            element={<ProjectSignaturePage />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="personnes" element={<LayoutPerson />}>
                      <Route index element={<PersonIndexPage />} />
                      <Route path="nouveau" element={<PersonNewPage />} />
                      <Route path=":uuid" element={<PersonPage />} />
                    </Route>
                    <Route path="portefeuille">
                      <Route index element={<WalletPage />} />
                      <Route path=":code" element={<ContractPage />} />
                      <Route
                        path="commissions"
                        element={<WalletCommissionsPage />}
                      />
                    </Route>
                  </Route>
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Route>,
            ),
          )}
        />
      </AppProvider>
    </SnackbarProvider>
  );
}

export default App;
