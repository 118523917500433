import React, { FC, memo, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import ButtonClose from 'components/Button/ButtonClose';
import { Form, Formik } from 'formik';
import { Project } from 'models/oav/Project.models';
import CircularProgress from '@mui/material/CircularProgress';
import { messageFieldRequired } from 'utils/validation/message';
import TextField from 'components/TextField/TextField';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { CardOfferSmall } from 'components/Card/CardOfferSmall';
import { useAccessToken } from 'utils/api/api';
import { requestDigitalSignature } from 'api/oav/Project.api';
import CardFormula from 'content/formula/CardFormula';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAppContext } from 'components/Context/AppContext';

interface DialogDigitalSignatureProps {
  project: Project;
  open: boolean;
  onClose: () => void;
}

interface FormikValues {
  subscriberMail: string;
}

type DialogDigitalSignatureContentProps = DialogDigitalSignatureProps;

const validationSchema = Yup.object().shape({
  subscriberMail: Yup.string()
    .required(messageFieldRequired)
    .email('Email non valide'),
});

const DialogDigitalSignatureContent: FC<DialogDigitalSignatureContentProps> = ({
  onClose,
  project,
}) => {
  const subscription = project.subscriptions![0];
  const [loading, setLoading] = useState(false);
  const accessToken = useAccessToken();
  const { addSnackbar } = useAppContext();
  const queryClient = useQueryClient();
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(project.id));
  const tarificationsQuery = useQuery(
    subscriptionQueries.getByIdTarification(project.id, subscription.id),
  );

  const { formula, offer } = useMemo(() => {
    const offer = offersMapQuery.data?.[subscription.offerCode];
    return {
      offer,
      formula: offer?.formulas.find(_ => _.code === subscription.formulaCode),
    };
  }, [offersMapQuery.data, subscription]);

  const pricing = useMemo(
    () =>
      formula
        ? tarificationsQuery.data?.formulas?.find(f => f.code === formula.code)
            ?.tarification?.pricing
        : undefined,
    [formula, tarificationsQuery.data],
  );

  const initialValues: FormikValues = useMemo(
    () => ({
      subscriberMail: project.subscriber.person.email ?? '',
    }),
    [project],
  );

  const handleSubmit = (values: FormikValues) => {
    setLoading(true);
    requestDigitalSignature(project.id, values, accessToken)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['projects', project.id],
          exact: true,
        });
        setLoading(false);
        onClose();
      })
      .catch(() => {
        setLoading(false);
        addSnackbar(
          getDefaultErrorSnackBar(
            "L'envoi de la signature électronique a echoué",
          ),
        );
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ values, errors, touched, handleBlur, handleChange }) => {
        return (
          <Form
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <DialogTitle>Signature électronique</DialogTitle>
            <ButtonClose
              disabled={loading}
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            />
            <DialogContent sx={{ width: '100%' }}>
              <Grid container columnSpacing={4} sx={{ height: '100%' }}>
                <Grid item xs={12}>
                  {offer && <CardOfferSmall offer={offer} />}
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography variant="body1">
                    Votre projet est sur le point d&#39;être signé
                    électroniquement par votre client. La mise en gestion sera
                    automatique après sa signature.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginY: 2 }}>
                  {formula && (
                    <CardFormula
                      formula={formula}
                      pricing={pricing}
                      recommended={
                        formula.code === subscription.recommendedFormulaCode
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>Envoyez par mail</strong> le lien de signature
                    électronique à votre client.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="filled"
                    sx={{
                      mt: 2,
                    }}
                    fullWidth
                    id="email"
                    name="subscriberMail"
                    label="Email de votre client"
                    value={values.subscriberMail}
                    error={touched.subscriberMail && !!errors.subscriberMail}
                    helperText={touched.subscriberMail && errors.subscriberMail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{ minLength: 1, maxLength: 100 }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 2,
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item sm="auto" xs={12}>
                  <Button
                    fullWidth
                    variant="text"
                    color="default"
                    onClick={onClose}
                    disabled={loading}
                    sx={{
                      px: 4,
                    }}
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item sm="auto" xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="gradient"
                    color="primary"
                    disabled={loading}
                    sx={{
                      px: 4,
                    }}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={28} />
                    ) : (
                      'Envoyer le lien de signature'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

const DialogDigitalSignature: React.FC<DialogDigitalSignatureProps> = props => {
  const { open } = props;

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogDigitalSignatureContent {...props} />
    </Dialog>
  );
};

export default memo(DialogDigitalSignature);
