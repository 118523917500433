import { Suspense } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

import ButtonClose from 'components/Button/ButtonClose';
import FormulaBox from 'content/formula/FormulaBox';
import WarrantySectionAccordion from 'content/warranty/WarrantySectionAccordion';
import { Formula } from 'models/referentiels/Formula.model';
import { TrfPricing } from 'models/referentiels/Tarification.models';
import { Offer } from 'models/referentiels/Offer.model';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import { warrantyQueries } from 'api/referentiels/WarrantyQuery.api';
import { TransitionSlideUP } from 'utils/animations/transitions';

export type FormulaDialogWarrantyProps = {
  open: boolean;
  offer: Offer;
  formula: Formula;
  pricing?: TrfPricing;
  onClose: () => void;
};

const FormulaDialogWarranty = (props: FormulaDialogWarrantyProps) => {
  const theme = useTheme();
  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

  const acronymsMapQuery = useQuery(warrantyQueries.getAcronymMap());
  const offerWarrantiesQuery = useQuery({
    ...offerQueries.getByCodeWarranties(props.offer.code),
  });

  return (
    <Dialog
      open={props.open}
      fullWidth
      fullScreen={!screenSizeUpSM}
      maxWidth="sm"
      TransitionComponent={TransitionSlideUP}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            background: theme.palette.background.default,
            borderBottom: 1,
            borderColor: theme.palette.divider,
          }}
        >
          <Box
            sx={{
              p: 2,
              pb: 1,
            }}
          >
            <ButtonClose
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              onClick={() => props.onClose()}
            />

            <FormulaBox formula={props.formula} pricing={props.pricing} />
          </Box>
        </Box>

        <Suspense>
          {props.open && (
            <Grid container spacing={2} sx={{ p: 2 }}>
              {offerWarrantiesQuery.data?.map((section, idx) => {
                return (
                  <Grid item xs={12} key={`warranties-section-${idx}`}>
                    <WarrantySectionAccordion
                      section={section}
                      formulas={[props.formula]}
                      onlyFormulas={[props.formula.code]}
                      labelOnLeftColumn={screenSizeUpSM}
                      acronymsMap={acronymsMapQuery.data}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};

FormulaDialogWarranty.displayName = 'FormulaDialogWarranty';

export default FormulaDialogWarranty;
