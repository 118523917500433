import { useRisks, useSegments } from 'queries';
import Stack from '@mui/material/Stack';
import SelectFilter from 'components/Select/SelectFilter';
import { Risk } from 'models/referentiels/Risk.model';
import { Segment } from 'models/referentiels/Segment';
import FilterChip from 'components/Chip/FilterChip';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { Form, useFormikContext } from 'formik';
import DatePicker from 'components/DatePicker/DatePicker';
import * as Yup from 'yup';
import { Dayjs } from 'dayjs';
import React from 'react';
import { useAccessToken } from 'utils/api/api';
import { useAppContext } from 'components/Context/AppContext';
import { getCsvCommissionList } from 'api/oav/Commission.api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { DownloadIcon } from 'components/Icon/DownloadIcon';

export const validationSchema = Yup.object().shape({
  startPaymentDate: Yup.date().typeError('Date non valide').nullable(),
  endPaymentDate: Yup.date().typeError('Date non valide').nullable(),
});

export interface WalletCommissionsFormData {
  risks: Risk[];
  segments: Segment[];
  startPaymentDate: Dayjs | null;
  endPaymentDate: Dayjs | null;
}

export const WalletCommissionsFilter = () => {
  const theme = useTheme();
  const accessToken = useAccessToken();
  const { addSnackbar } = useAppContext();

  const { data: segmentOptions } = useSegments();

  const { data: riskOptions } = useRisks();

  const { values, errors, touched, handleBlur, setFieldValue, resetForm } =
    useFormikContext<WalletCommissionsFormData>();

  const showStartPaymentDatePill =
    values.startPaymentDate && values.startPaymentDate.isValid();
  const showEndPaymentDatePill =
    values.endPaymentDate && values.endPaymentDate.isValid();

  const showPills =
    values.risks.length > 0 ||
    values.segments.length > 0 ||
    showStartPaymentDatePill ||
    showEndPaymentDatePill;

  const onDownloadHandle = () => {
    getCsvCommissionList(
      {
        inRisks: values.risks.map(r => r.label),
        inSegments: values.segments.map(s => s.label),
        startPaymentDate: values.startPaymentDate
          ? values.startPaymentDate.toDate()
          : null,
        endPaymentDate: values.endPaymentDate
          ? values.endPaymentDate.toDate()
          : null,
      },
      accessToken,
    )
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'commissions.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(() => {
        addSnackbar(
          getDefaultErrorSnackBar(
            'Une erreur est survenue durant le téléchargement du fichier',
          ),
        );
      });
  };

  return (
    <Stack sx={{ marginTop: 7, marginBottom: 5 }}>
      <Form>
        <Stack direction="row" gap={2}>
          <DatePicker
            sx={{
              fieldset: {
                borderWidth: 2,
                borderColor: theme.palette.text.primary,
              },
            }}
            variant="outlined"
            label="Début de paiement"
            format="DD/MM/YYYY"
            openTo="year"
            name="startPaymentDate"
            maxDate={values.endPaymentDate}
            value={values.startPaymentDate ? values.startPaymentDate : 'clear'}
            onChange={v => setFieldValue('startPaymentDate', v)}
            slotProps={{
              textField: {
                fullWidth: false,
                onBlur: handleBlur,
                error: !!touched.startPaymentDate && !!errors.startPaymentDate,
                helperText: !!touched.startPaymentDate && (
                  <>{errors.startPaymentDate}</>
                ),
              },
            }}
          />
          <DatePicker
            sx={{
              fieldset: {
                borderWidth: 2,
                borderColor: theme.palette.text.primary,
              },
            }}
            variant="outlined"
            label="Fin de paiement"
            format="DD/MM/YYYY"
            openTo="year"
            name="endPaymentDate"
            minDate={values.startPaymentDate}
            value={values.endPaymentDate ? values.endPaymentDate : 'clear'}
            onChange={v => setFieldValue('endPaymentDate', v)}
            slotProps={{
              textField: {
                fullWidth: false,
                onBlur: handleBlur,
                error: !!touched.endPaymentDate && !!errors.endPaymentDate,
                helperText: !!touched.endPaymentDate && (
                  <>{errors.endPaymentDate}</>
                ),
              },
            }}
          />
          <SelectFilter
            disabled={false}
            onChange={(_, value) => setFieldValue('risks', value)}
            multiple={true}
            showCount={true}
            options={riskOptions}
            value={values.risks}
            getOptionKey={option => option.code}
            renderElement={option => option.label}
          >
            Risque
          </SelectFilter>
          <SelectFilter
            disabled={false}
            onChange={(_, value) => setFieldValue('segments', value)}
            multiple={true}
            showCount={true}
            options={segmentOptions}
            value={values.segments}
            getOptionKey={option => option.code}
            renderElement={option => option.label}
          >
            Segment
          </SelectFilter>
          <Link sx={{ marginLeft: 2, marginTop: 1 }} onClick={onDownloadHandle}>
            <DownloadIcon
              height="35"
              width="35"
              color={theme.palette.text.primary}
            />
          </Link>
        </Stack>
      </Form>
      {showPills && (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          spacing={2}
          sx={{ mt: 2 }}
        >
          {showStartPaymentDatePill && (
            <FilterChip
              key={'dateStart'}
              item={{
                label:
                  'Après le ' + values.startPaymentDate?.format('DD/MM/YYYY'),
              }}
              theme={theme}
              onDelete={() => setFieldValue('startPaymentDate', null)}
            />
          )}

          {showEndPaymentDatePill && (
            <FilterChip
              key={'dateEnd'}
              item={{
                label:
                  'Avant le ' + values.endPaymentDate?.format('DD/MM/YYYY'),
              }}
              theme={theme}
              onDelete={() => setFieldValue('endPaymentDate', null)}
            />
          )}

          {values.risks.map(item => (
            <FilterChip
              key={item.code}
              item={item}
              theme={theme}
              onDelete={() =>
                setFieldValue(
                  'risks',
                  values.risks.filter(element => element.code !== item.code),
                )
              }
            />
          ))}
          {values.segments.map(item => (
            <FilterChip
              key={item.code}
              item={item}
              theme={theme}
              onDelete={() =>
                setFieldValue(
                  'segments',
                  values.segments.filter(element => element.code !== item.code),
                )
              }
            />
          ))}
          <Link onClick={() => resetForm()}>Tout effacer</Link>
        </Stack>
      )}
    </Stack>
  );
};
