//@ts-ignore
import React, { ReactNode } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import { appName } from 'App';
import { projectMutations, projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { ChipOfferRisk } from 'components/Chip/ChipOfferRisk';
import { ChipProjectStatus } from 'components/Chip/ChipProjectStatus';
import { ProjectStatus } from 'models/oav/ProjectStatus.models';
import PriceNumber from 'content/formula/PriceNumber';
import { commercialPropositionQueries } from 'api/oav/CommercialPropositionQuery.api';
import {
  ContractualDocument,
  ContractualDocumentType,
} from 'models/oav/ContractualDocument.models';
import { useAppContext } from 'components/Context/AppContext';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { SignatureStatus } from 'models/oav/CommercialProposition.models';
import { useAccessToken } from 'utils/api/api';
import { formatDate, formatTime } from 'utils/date';

const CardContent = ({ children }: { children: ReactNode }) => (
  <Grid xs={12} item>
    <Card variant="outlined" sx={{ p: 2 }}>
      {children}
    </Card>
  </Grid>
);

const ProjectPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { addSnackbar } = useAppContext();
  const { id } = useParams();
  const theme = useTheme();

  if (!id) return <></>;

  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));
  const cancelSignatureLinkMutation =
    projectMutations.useCancelSignatureLinkMutation({
      onError: () => {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      },
    });
  const commercialPropositionQueryOptions =
    commercialPropositionQueries.get(id);
  const commercialPropositionQuery = useQuery(
    commercialPropositionQueryOptions,
  );

  const subscription = projectQuery.data?.subscriptions?.[0];
  const tarificationsQuery = useQuery({
    ...subscriptionQueries.getByIdTarification(id, subscription?.id || ''),
    enabled: !!subscription?.id,
  });

  const error = projectQuery.error || commercialPropositionQuery.error;
  if (error) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={error instanceof RestError ? error.status : undefined}
        />
      </Grid>
    );
  }

  if (!projectQuery.data || !subscription || !commercialPropositionQuery.data) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const offerCode = subscription.offerCode;
  const offer = offersMapQuery.data?.[offerCode];
  const formulaCode = subscription.formulaCode!;
  const formula = offer?.formulas?.find(_ => _.code === formulaCode);
  const pricing = tarificationsQuery.data?.formulas?.find(
    f => f.code === formulaCode,
  )?.tarification?.pricing;
  const subscriber = projectQuery.data?.subscriber?.person;

  const signedTerminationMandate =
    commercialPropositionQuery.data.contractualDocuments.find(
      _ =>
        _.type == ContractualDocumentType.MANDAT_CANCELATION &&
        _.signatureStatus == SignatureStatus.SIGNED,
    );

  const membershipCertificate =
    commercialPropositionQuery.data.contractualDocuments.find(
      _ => _.type == ContractualDocumentType.MEMBERSHIP_CERTIFICATE,
    );

  const downloadFile = (fileURL: string, filename: string) => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    link.click();
    setTimeout(() => link.remove(), 500);
  };

  const downloadContractualDocument = (document: ContractualDocument) => {
    queryClient
      .fetchQuery(
        commercialPropositionQueries.getContractualDocument(
          accessToken,
          id,
          document.id,
        ),
      )
      .then(doc => downloadFile(doc.downloadUrl!, document.filename))
      .catch(() => {
        addSnackbar(
          getDefaultErrorSnackBar(
            'Une erreur est survenue durant le téléchargement du document.',
          ),
        );
      });
  };

  const downloadCommercialPropositionDocument = () => {
    queryClient
      .fetchQuery(commercialPropositionQueryOptions)
      .then(doc => downloadFile(doc.downloadUrl!, doc.filename))
      .catch(() => {
        addSnackbar(
          getDefaultErrorSnackBar(
            'Une erreur est survenue durant le téléchargement du document.',
          ),
        );
      });
  };

  const linkExpired = commercialPropositionQuery.data.signatureExpireAt
    ? commercialPropositionQuery.data.signatureExpireAt.getTime() -
        Date.now() <=
      0
    : false;

  const informationMessage =
    projectQuery.data?.status == ProjectStatus.MANAGEMENT_FAILED ? (
      <>
        La mise en gestion de cette souscription a échoué. Veuillez transmettre
        votre dossier de souscription par e-mail à{' '}
        <Link href="mailto:ug55@mutuellegsmc.fr">ug55@mutuellegsmc.fr</Link>.
      </>
    ) : projectQuery.data?.status == ProjectStatus.MANAGEMENT_DONE ? (
      <>Cette souscription est validée avec le n° de contrat ci-dessous :</>
    ) : projectQuery.data?.status == ProjectStatus.SIGNATURE_DONE ? (
      <>Cette souscription est en cours de mise en gestion.</>
    ) : undefined;

  const cancelSignatureLink = () => {
    cancelSignatureLinkMutation.mutate({ projectId: id });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Signature</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {informationMessage && (
                <CardContent>
                  <Typography variant="body1">{informationMessage}</Typography>
                </CardContent>
              )}
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Stack
                      gap={1}
                      alignItems="space-between"
                      sx={{
                        height: '100%',
                      }}
                    >
                      <Typography variant="body1">
                        <b>
                          {subscriber.civility} {subscriber.firstname}{' '}
                          {subscriber.lastname}
                        </b>
                      </Typography>
                      <Typography variant="body2">
                        <Stack
                          direction="row"
                          gap={1}
                          flexWrap="wrap-reverse"
                          useFlexGap
                        >
                          <b>{offer?.label}</b>
                          {offer?.risks?.map(_ => (
                            <ChipOfferRisk key={_.code} risk={_} />
                          ))}
                        </Stack>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: formula?.color || theme.palette.text.primary,
                        }}
                      >
                        {formula?.label}
                      </Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ maxWidth: '200px' }}
                        spacing={2}
                      >
                        <Typography variant="caption" noWrap>
                          par mois
                        </Typography>
                        <Typography variant="body1" noWrap>
                          <PriceNumber
                            price={pricing?.perMonth}
                            currency={pricing?.currency}
                          />
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ maxWidth: '200px' }}
                        spacing={2}
                      >
                        <Typography variant="caption" noWrap>
                          par an
                        </Typography>
                        <Typography variant="body1" noWrap>
                          <PriceNumber
                            price={pricing?.perYear}
                            currency={pricing?.currency}
                          />
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Grid container gap={2} sx={{ height: '100%' }}>
                      <Grid item xs={12}>
                        <Stack
                          gap={2}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                          flexWrap="wrap-reverse"
                          sx={{ width: '100%' }}
                        >
                          {subscription.contractCode && (
                            <Box>
                              <Typography variant="body1">
                                N° de contrat
                              </Typography>
                              <Typography variant="body2">
                                {subscription.contractCode}
                              </Typography>
                            </Box>
                          )}

                          {projectQuery.data.status && (
                            <ChipProjectStatus
                              sx={{ ml: 'auto' }}
                              projectStatus={projectQuery.data.status}
                            />
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 'auto' }}>
                        <Stack
                          gap={2}
                          justifyContent="flex-end"
                          direction="row"
                          flexWrap="wrap-reverse"
                        >
                          {membershipCertificate && (
                            <Button
                              endIcon={
                                <DownloadIcon sx={{ width: 24, height: 24 }} />
                              }
                              color="default"
                              sx={{ width: 'fit-content' }}
                              onClick={() =>
                                downloadContractualDocument(
                                  membershipCertificate,
                                )
                              }
                            >
                              Certificat d’adhésion
                            </Button>
                          )}

                          <Button
                            endIcon={
                              <DownloadIcon sx={{ width: 24, height: 24 }} />
                            }
                            color="default"
                            sx={{ width: 'fit-content' }}
                            onClick={() =>
                              downloadCommercialPropositionDocument()
                            }
                          >
                            Dossier de souscription
                            {`${
                              commercialPropositionQuery.data
                                ?.signatureStatus == SignatureStatus.SIGNED
                                ? ' signé'
                                : ''
                            }`}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>

              {signedTerminationMandate && (
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography variant="body1">
                        Mandat de résiliation 📄
                      </Typography>
                      <Typography variant="body2">
                        Veuillez transmettre votre mandat de résiliation à
                        l&#39;assureur actuel de votre client via{' '}
                        <Link
                          href="https://jeresiliemoncontrat.com"
                          target="_blank"
                        >
                          jeresiliemoncontrat.com
                        </Link>
                        .
                      </Typography>
                    </Box>
                    <Button
                      sx={{
                        alignSelf: 'flex-end',
                        minWidth: 'fit-content',
                      }}
                      endIcon={<DownloadIcon sx={{ width: 24, height: 24 }} />}
                      color="default"
                      onClick={() =>
                        downloadContractualDocument(signedTerminationMandate)
                      }
                    >
                      Mandat de résiliation signé
                    </Button>
                  </Stack>
                </CardContent>
              )}
              {projectQuery.data.status ==
                ProjectStatus.SIGNATURE_IN_PROGRESS && (
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography variant="body1">
                        Signature électronique ✍️
                      </Typography>
                      <Typography variant="body2">
                        {linkExpired ? (
                          <>
                            Ce projet est finalisé, le lien de signature
                            électronique a été envoyé le{' '}
                            {formatDate(
                              commercialPropositionQuery.data.signatureSentAt!,
                            )}{' '}
                            à{' '}
                            {formatTime(
                              commercialPropositionQuery.data.signatureSentAt!,
                            )}{' '}
                            mais n&#39;a pas été signé dans le délai de 3 jours
                            par votre client.
                          </>
                        ) : (
                          <>
                            Ce projet est finalisé, le lien de signature
                            électronique a été envoyé le{' '}
                            {formatDate(
                              commercialPropositionQuery.data.signatureSentAt!,
                            )}{' '}
                            à{' '}
                            {formatTime(
                              commercialPropositionQuery.data.signatureSentAt!,
                            )}{' '}
                            et est en attente de signature par votre client.
                          </>
                        )}
                      </Typography>
                    </Box>
                    <Button
                      sx={{
                        alignSelf: 'flex-end',
                        minWidth: 'fit-content',
                      }}
                      disabled={cancelSignatureLinkMutation.isPending}
                      color={linkExpired ? 'default' : 'error'}
                      onClick={cancelSignatureLink}
                      endIcon={
                        cancelSignatureLinkMutation.isPending ? (
                          <CircularProgress color="inherit" size={26} />
                        ) : undefined
                      }
                    >
                      {linkExpired ? 'Éditer le projet' : 'Annuler'}
                    </Button>
                  </Stack>
                </CardContent>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <RouterLink to="/dashboard">
          <Button fullWidth color="primary" sx={{ px: 4 }}>
            Aller au Tableau de bord
          </Button>
        </RouterLink>
      </Grid>
    </Grid>
  );
};

export default ProjectPage;
