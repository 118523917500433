import { Icon } from '.';
import { IconProps } from 'components/Icon/settings';

export const WalletIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 16L25 12C25 10.8954 24.1046 10 23 10L3 10C1.89543 10 1 10.8954 1 12L1 26C1 27.1046 1.89543 28 3 28L23 28C24.1046 28 25 27.1046 25 26L25 22"
          stroke={props.color}
          strokeWidth="2"
        />
        <mask id="path-2-inside-1_2136_18243" fill="white">
          <rect x="14" y="15" width="14" height="8" rx="1" />
        </mask>
        <rect
          x="14"
          y="15"
          width="14"
          height="8"
          rx="1"
          stroke={props.color}
          strokeWidth="4"
          mask="url(#path-2-inside-1_2136_18243)"
        />
        <path
          d="M21.8599 7.18822L21.3579 5.31505C21.215 4.78158 20.6667 4.465 20.1332 4.60794L10.7351 7.12614"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.143 2.98065L15.731 2.2669C15.4548 1.78861 14.8432 1.62474 14.3649 1.90088L5.31635 7.12508"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
