import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import ButtonClose from 'components/Button/ButtonClose';
import { Document } from 'models/oav/Document.model';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import CardError from 'components/Card/CardError';

interface PreviewDocumentProps {
  document?: Document | null;
  loading?: boolean;
  onClose?: () => void;
}

const PreviewDocument: React.FC<PreviewDocumentProps> = ({
  document,
  onClose,
  loading,
}) => {
  const documentQuery = useQuery({
    queryKey: ['document', document?.downloadUrl],
    queryFn: () =>
      fetch(document!.downloadUrl!).then(
        async response => await response.blob(),
      ),
    enabled: !!document?.downloadUrl,
    staleTime: 0,
    gcTime: 0,
  });

  const [blobUrl, setBlobUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (documentQuery.data && !!document) {
      setBlobUrl(URL.createObjectURL(documentQuery.data));
    }
  }, [documentQuery.data, document]);

  return (
    <Card
      sx={{
        padding: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {onClose && <ButtonClose sx={{ ml: 'auto' }} onClick={onClose} />}
      <div style={{ height: '100%', overflow: 'auto' }}>
        {documentQuery.error ? (
          <CardError />
        ) : loading || documentQuery.isLoading || !blobUrl || !document ? (
          <Skeleton height="100%" width="100%" sx={{ transform: 'unset' }} />
        ) : (
          <>
            {document.mimeType.startsWith('image/') ? (
              <img
                alt="Pièce justificative"
                src={blobUrl}
                style={{ width: '100%' }}
              />
            ) : (
              <embed
                key={document.id}
                src={blobUrl}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default PreviewDocument;
