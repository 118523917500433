import { PaletteOptions } from '@mui/material/styles';

export const paletteLight: PaletteOptions = {
  primary: {
    main: '#7992EA',
    contrastText: '#EFF3FC',
  },
  secondary: {
    main: '#F89136',
    contrastText: '#EFF3FC',
  },
  error: {
    main: '#F23A3A',
    contrastText: '#EFF3FC',
  },
  warning: {
    main: '#F89136',
    contrastText: '#EFF3FC',
  },
  info: {
    main: '#2155CD',
    contrastText: '#EFF3FC',
  },
  success: {
    main: '#52E09C',
    contrastText: '#EFF3FC',
  },
  text: {
    primary: '#090E1E',
    secondary: '#2C3555',
    disabled: '#8C94AB',
  },
  background: {
    default: '#EFF3FC',
    paper: '#E7ECFB',
  },
  action: {
    disabled: '#8C94AB',
    disabledBackground: '#8C94AB',
  },
  divider: '#D3D3ff',
};

export const opacityLight = {
  card: 0.2,
  chip: 0.2,
  disabled: 0.05,
  field: 0.05,
  focused: 0.05,
  hover: 0.15,
  pre: 0.1,
  scrollbarThumb: 0.8,
  tooltip: 0.05,
};
