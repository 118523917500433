import { useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Outlet, useSearchParams } from 'react-router-dom';

import { User } from 'oidc-client-ts';
import { userManager } from '../config';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const ProtectedApp: React.FC = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  const queryExchangeToken = async (token: string) => {
    const url =
      process.env.REACT_APP_KEYCLOAK_AUTHORITY +
      '/protocol/openid-connect/token';
    const grantTypeTokenExchange =
      'urn:ietf:params:oauth:grant-type:token-exchange';
    const tokenTypeAccessToken =
      'urn:ietf:params:oauth:token-type:access_token';
    const requestedTokenTypeRefreshToken =
      'urn:ietf:params:oauth:token-type:refresh_token';

    const details = {
      grant_type: grantTypeTokenExchange,
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
      client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET || '',
      subject_token: token,
      subject_token_type: tokenTypeAccessToken,
      requested_token_type: requestedTokenTypeRefreshToken,
    };

    const formBody: string[] = [];
    let property: keyof typeof details;
    for (property in details) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
      },
      body: formBody.join('&'),
    });
    return await response.json();
  };

  useEffect(() => {
    // check token in url for henner purpose
    const accessToken = searchParams.get('access_token');

    if (accessToken) {
      searchParams.delete('access_token');
      queryExchangeToken(accessToken).then(response => {
        const user = new User({ ...response });

        userManager.storeUser(user).then(() => {
          userManager.getUser().then(() => {
            window.location.href = '/';
          });
        });
      });
    } else if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      void auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin, userManager]);

  return (
    <>
      {auth.error ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="h1">Une erreur est survenue</Typography>
          <Typography>{auth.error?.message}</Typography>
        </Box>
      ) : auth.isLoading ? (
        <LinearProgress sx={{ borderRadius: 0 }} />
      ) : !auth.isAuthenticated || auth.user === null ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="h1">Une erreur est survenue</Typography>
          <Typography>Impossible de s&apos;authentifier</Typography>
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default ProtectedApp;
