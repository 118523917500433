import {
  Project,
  ProjectStepAddressDetails,
  ProjectStepClientsInfo,
  ProjectStepContract,
  ProjectStepFormulaChoice,
  ProjectStepNeedsRequest,
  ProjectStepProposal,
} from 'models/oav/Project.models';
import { useAccessToken } from 'utils/api/api';
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  fetchProjectStepContractValidation,
  updateProjectStepAddressDetails,
  updateProjectStepClientsInfo,
  updateProjectStepContract,
  updateProjectStepFormulaChoice,
  updateProjectStepNeeds,
  updateProjectStepProposal,
} from 'api/oav/ProjectStep.api';

// 30 sec.
const defaultStaleTime = 30000;

const updateProjectStepClientsInfoMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ProjectStepClientsInfo) =>
      updateProjectStepClientsInfo(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

const updateProjectStepNeedsMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: ProjectStepNeedsRequest) =>
      updateProjectStepNeeds(accessToken, id, params),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

const updateProjectStepAddressDetailsMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ProjectStepAddressDetails) =>
      updateProjectStepAddressDetails(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

const updateProjectStepProposalMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ProjectStepProposal) =>
      updateProjectStepProposal(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

const updateProjectStepFormulaChoiceMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ProjectStepFormulaChoice) =>
      updateProjectStepFormulaChoice(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

const updateProjectStepContractMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ProjectStepContract) =>
      updateProjectStepContract(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const projectStepGetContractValidationOptions = (projectId: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'step', 'contract-validation'],
    queryFn: () => fetchProjectStepContractValidation(token, projectId),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};

export const projectStepMutations = {
  updateProjectStepClientsInfo: updateProjectStepClientsInfoMutation,
  updateProjectStepNeeds: updateProjectStepNeedsMutation,
  updateProjectStepAddressDetails: updateProjectStepAddressDetailsMutation,
  updateProjectStepProposal: updateProjectStepProposalMutation,
  updateProjectStepFormulaChoice: updateProjectStepFormulaChoiceMutation,
  updateProjectStepContract: updateProjectStepContractMutation,
};

export const projectStepQueries = {
  getContractValidation: projectStepGetContractValidationOptions,
};
