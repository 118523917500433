import { PaletteOptions } from '@mui/material/styles';

export const paletteBlue: PaletteOptions = {
  primary: {
    main: '#5FDED6',
  },
  secondary: {
    main: '#F89136',
    contrastText: '#E5EBF6',
  },
  error: {
    main: '#FF5D5D',
  },
  warning: {
    main: '#F89136',
    contrastText: '#E5EBF6',
  },
  info: {
    main: '#2155CD',
  },
  success: {
    main: '#52E09C',
  },
  text: {
    primary: '#E5EBF6',
    secondary: '#D9DBE0',
    disabled: '#D9DBE0',
  },
  background: {
    default: '#1D2943',
    paper: '#364158',
  },
  action: {
    disabled: '#AFB6C8',
    disabledBackground: '#888DA2',
  },
  divider: '#475370',
};

export const opacityBlue = {
  card: 0.15,
  chip: 0.2,
  disabled: 0.2,
  field: 0.15,
  focused: 0.15,
  hover: 0.2,
  pre: 0.2,
  scrollbarThumb: 0.8,
  tooltip: 0.2,
};
