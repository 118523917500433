import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useProjectContext } from 'components/Context/ProjectContext';
import TextField from 'components/TextField/TextField';
import React, { useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { CardOfferSmall } from 'components/Card/CardOfferSmall';
import { useAppContext } from 'components/Context/AppContext';
import {
  getDefaultErrorSnackBar,
  getDefaultSuccessSnackBar,
} from 'utils/snackbars/Snackbars';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { yupEmail } from 'utils/validation/yupPerson';
import Skeleton from '@mui/material/Skeleton';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { messageFieldRequired } from 'utils/validation/message';
import { CloseIcon } from 'components/Icon/CloseIcon';
import { Icon } from 'components/Icon';
import { useTheme } from '@mui/material/styles';
import { CommercialProposition } from 'models/oav/CommercialProposition.models';
import {
  staticDocumentLabels,
  StaticDocumentTypeCodes,
} from 'models/referentiels/Document.models';
import CardFormula from 'content/formula/CardFormula';
import { commercialPropositionMutations } from 'api/oav/CommercialPropositionQuery.api';

export const validationSchema = Yup.object().shape({
  email: yupEmail.required(messageFieldRequired),
});

const offerDocumentsMap: { [key: string]: StaticDocumentTypeCodes[] } = {
  '312': [
    StaticDocumentTypeCodes.DOCUMENT_INFORMATION,
    StaticDocumentTypeCodes.REGLEMENT_MUTUALISTE,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES_ASSISTANCE,
    StaticDocumentTypeCodes.STATUTS_GSMC,
  ],
  '512': [
    StaticDocumentTypeCodes.DOCUMENT_INFORMATION,
    StaticDocumentTypeCodes.REGLEMENT_MUTUALISTE,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES_ASSISTANCE,
    StaticDocumentTypeCodes.STATUTS_GSMC,
  ],
  '539': [
    StaticDocumentTypeCodes.DOCUMENT_INFORMATION,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES_ASSISTANCE,
    StaticDocumentTypeCodes.STATUTS_GSMC,
    StaticDocumentTypeCodes.ANIP,
  ],
};

export const CommercialPropositionDialog = () => {
  const { id: projectId } = useParams();
  const validatedWithMail = useRef(false);

  if (!projectId) return <></>;

  const theme = useTheme();

  const projectQueryOptions = projectQueries.getById(projectId);
  const projectQuery = useQuery(projectQueryOptions);
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(projectId));

  const { closeCommercialPropositionDialog } = useProjectContext();

  const { addSnackbar } = useAppContext();
  const queryClient = useQueryClient();

  const project = projectQuery.data;
  // TODO: Handle multi subscriptions
  const subscription = project?.subscriptions?.[0];

  const offerCode = subscription?.offerCode || '';

  const offer = offersMapQuery.data?.[offerCode];

  const formulaCodes = useMemo(
    () =>
      subscription?.formulaCode
        ? [subscription.formulaCode]
        : (subscription?.shoppingCartItems?.map(_ => _.formulaCode) ?? []),
    [subscription],
  );

  const tarificationsQuery = useQuery({
    ...subscriptionQueries.getByIdTarification(
      projectId,
      subscription?.id || '',
    ),
    enabled: !!subscription?.id,
  });

  const handleSuccess = async (data: CommercialProposition) => {
    if (validatedWithMail.current) {
      addSnackbar(
        getDefaultSuccessSnackBar('Le projet a été envoyé avec succès.'),
      );
    } else {
      window.open(data.downloadUrl, '_blank');
    }

    try {
      await queryClient.invalidateQueries({
        queryKey: projectQueryOptions.queryKey,
      });
    } catch (e) {
      // Do not throw error if the invalidate fails
      console.error(e);
    }

    closeCommercialPropositionDialog();
  };

  const handleError = () => {
    addSnackbar(
      getDefaultErrorSnackBar(
        'Une erreur est survenue durant la génération du projet.',
      ),
    );
  };

  const { isPending: isMutationPending, mutate } =
    commercialPropositionMutations.useCreate(handleSuccess, handleError);

  const title = 'Édition de projet';

  if (offersMapQuery.error || projectQuery.error) {
    return (
      <ErrorDialog onClose={closeCommercialPropositionDialog} title={title} />
    );
  }

  if (!project || !subscription || !offer) {
    return <LoadingDialog title={title} />;
  }

  const documentsToDisplay = offerDocumentsMap[subscription.offerCode] || [];

  const handleSubmitWithEmail = async (values: { email: string }) => {
    validatedWithMail.current = true;

    mutate({
      projectId: project.id,
      payload: {
        sendEmail: true,
        email: values.email,
      },
    });
  };

  const handleSubmitWithoutEmail = async () => {
    validatedWithMail.current = false;

    mutate({
      projectId: project.id,
      payload: {
        sendEmail: false,
      },
    });
  };

  const loadingWithMail = isMutationPending && validatedWithMail.current;
  const loadingWithoutMail = isMutationPending && !loadingWithMail;

  return (
    <Formik
      initialValues={{
        email: project.subscriber?.person?.email ?? '',
      }}
      onSubmit={handleSubmitWithEmail}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <DialogTitle>{title}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeCommercialPropositionDialog}
            disabled={isMutationPending}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              ':hover': {
                backgroundColor: 'rgba(250, 250, 250, 0.04)',
              },
            }}
          >
            <Icon color={theme.palette.text.primary}>
              <CloseIcon />
            </Icon>
          </IconButton>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CardOfferSmall offer={offer} />
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  Vous avez sélectionné les formules suivantes :
                </Typography>
                <Grid container spacing={2}>
                  {offer.formulas
                    .filter(_ => formulaCodes.includes(_.code))
                    .map(_ => {
                      const pricing = tarificationsQuery.data
                        ? tarificationsQuery.data.formulas?.find(
                            f => f.code === _.code,
                          )?.tarification?.pricing
                        : undefined;
                      return (
                        <Grid key={_.code} item sm xs={12} sx={{ mt: 2 }}>
                          <CardFormula
                            key={_.code}
                            formula={_}
                            pricing={pricing}
                            recommended={
                              _.code === subscription?.recommendedFormulaCode
                            }
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>

              {documentsToDisplay && documentsToDisplay.length > 0 && (
                <Grid item xs={12}>
                  <Typography>
                    <b>Envoyez par mail</b> ou&nbsp;
                    <b>téléchargez</b> votre projet complet qui comprend les
                    éléments suivants :
                  </Typography>
                  <ul>
                    <li>Votre étude personnalisée</li>
                    <li>Votre proposition d&apos;assurance</li>
                    <li>Votre dossier de souscription</li>
                    {documentsToDisplay.map((document, index) => (
                      <li key={index}>
                        {staticDocumentLabels[document]?.label}
                      </li>
                    ))}
                  </ul>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  fullWidth
                  id="email"
                  name="email"
                  label="Email de votre client"
                  value={values.email}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ minLength: 1, maxLength: 100 }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions
            sx={{
              p: 2,
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  variant="text"
                  color="default"
                  onClick={closeCommercialPropositionDialog}
                  disabled={isMutationPending}
                  sx={{
                    px: 4,
                  }}
                >
                  Annuler
                </Button>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="gradient"
                  color="primary"
                  disabled={loadingWithMail || loadingWithoutMail}
                  sx={{
                    px: 4,
                  }}
                >
                  {loadingWithMail ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    'Envoyer par mail'
                  )}
                </Button>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  variant="gradient"
                  color="primary"
                  onClick={handleSubmitWithoutEmail}
                  disabled={loadingWithMail || loadingWithoutMail}
                  sx={{
                    px: 4,
                  }}
                >
                  {loadingWithoutMail ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    'Télécharger'
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

const LoadingDialog = ({ title }: { title: string }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="100px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
    </DialogContent>
  </>
);

const ErrorDialog = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <ErrorMessage message="Une erreur est survenue" />
    </DialogContent>
    <DialogActions>
      <Button color="default" onClick={onClose}>
        Fermer
      </Button>
    </DialogActions>
  </>
);
