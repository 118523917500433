import { Document } from './Document.model';
import { ContractualDocument } from 'models/oav/ContractualDocument.models';

export type CommercialProposition = {
  contractualDocuments: ContractualDocument[];
  signatureStatus: SignatureStatus;
  signatureSentAt?: Date;
  signatureExpireAt?: Date;
} & Document;

export type CommercialPropositionRequest = {
  sendEmail: boolean;
  email?: string;
};

export enum SignatureStatus {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  SIGNED = 'SIGNED',
}
