import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.REACT_APP_SENTRY_ENABLED === '1',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

export const captureError = (label: string, status: number, extra: any) => {
  Sentry.captureMessage(label, {
    level: 'error',
    tags: { key: 'service', label: 'oav-front' },
    extra: {
      status: status,
      ...extra,
    },
  });
};
