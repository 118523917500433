import { useAccessToken } from 'utils/api/api';
import { queryOptions, useMutation } from '@tanstack/react-query';
import {
  createCommercialProposition,
  getCommercialProposition,
  getContractualDocument,
} from 'api/oav/CommercialProposition.api';
import {
  CommercialProposition,
  CommercialPropositionRequest,
} from 'models/oav/CommercialProposition.models';

// 30 sec.
const defaultStaleTime = 30000;

const getCommercialPropositionOptions = (projectId: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'commercialProposition'],
    queryFn: () => getCommercialProposition(token, projectId),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};

const getContractualDocumentOptions = (
  accessToken: string,
  projectId: string,
  documentId: string,
) => {
  return queryOptions({
    queryKey: [
      'projects',
      projectId,
      'commercialProposition',
      'contractual-documents',
      documentId,
    ],
    queryFn: () => getContractualDocument(accessToken, projectId, documentId),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};

export const useCreateCommercialPropositionMutation = (
  onSuccess?: (data: CommercialProposition) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  return useMutation({
    mutationFn: (params: {
      projectId: string;
      payload: CommercialPropositionRequest;
    }) =>
      createCommercialProposition(
        accessToken,
        params.projectId,
        params.payload,
      ),
    onSuccess: (commercialProposition: CommercialProposition) => {
      onSuccess?.(commercialProposition);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const commercialPropositionQueries = {
  get: getCommercialPropositionOptions,
  getContractualDocument: getContractualDocumentOptions,
};

export const commercialPropositionMutations = {
  useCreate: useCreateCommercialPropositionMutation,
};
