import FolderZipIcon from '@mui/icons-material/FolderZip';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 o';

  const kilo = 1024;
  const units = ['o', 'Ko', 'Mo', 'Go', 'To'];
  const index = Math.floor(Math.log(bytes) / Math.log(kilo));
  const value = bytes / Math.pow(kilo, index);

  return `${value.toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} ${units[index]}`;
};

export type MimeTypeDetails = {
  extensions: string[];
  icon: JSX.Element;
  previewEnabled?: boolean;
};

export enum MimeType {
  'APPLICATION_PDF' = 'application/pdf',
  'APPLICATION_ZIP' = 'application/zip',
  'IMAGE_BMP' = 'image/bmp',
  'IMAGE_JPG' = 'image/jpeg',
  'IMAGE_PNG' = 'image/png',
  'IMAGE_WEBP' = 'image/webp',
}

export const mimeTypeDetails: Record<MimeType, MimeTypeDetails> = {
  [MimeType.APPLICATION_PDF]: {
    extensions: ['pdf'],
    icon: <InsertDriveFileIcon style={{ color: '#EB1000' }} />,
    previewEnabled: true,
  },
  [MimeType.APPLICATION_ZIP]: {
    extensions: ['zip'],
    icon: <FolderZipIcon style={{ color: '#FFB900' }} />,
    previewEnabled: false,
  },
  [MimeType.IMAGE_BMP]: {
    extensions: ['bmp'],
    icon: <ImageIcon />,
    previewEnabled: true,
  },
  [MimeType.IMAGE_JPG]: {
    extensions: ['jpeg', 'jpg'],
    icon: <ImageIcon />,
    previewEnabled: true,
  },
  [MimeType.IMAGE_PNG]: {
    extensions: ['png'],
    icon: <ImageIcon />,
    previewEnabled: true,
  },
  [MimeType.IMAGE_WEBP]: {
    extensions: ['webp'],
    icon: <ImageIcon />,
    previewEnabled: true,
  },
};

export const getMimeTypeFromExtension = (
  extension: string,
): MimeType | undefined => {
  for (const [mimeType, details] of Object.entries(mimeTypeDetails)) {
    if (details.extensions.includes(extension.toLowerCase())) {
      return mimeType as MimeType;
    }
  }

  return undefined;
};
