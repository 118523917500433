import { useTheme } from '@mui/material/styles';
import { PersonCircleIcon } from 'components/Icon/PersonCircleIcon';
import { PersonDuoCircleIcon } from 'components/Icon/PersonDuoCircleIcon';
import { PersonTrioCircleIcon } from 'components/Icon/PersonTrioCircleIcon';
import { BeneficiaryType } from 'models/oav/Beneficiary.models';
import FormCard from 'content/form/FormCard';

export type FormBeneficiaryExpressCardProps = {
  type: BeneficiaryType;
  count?: number;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (_: boolean) => void;
  children: JSX.Element;
};

const FormBeneficiaryExpressCard = (props: FormBeneficiaryExpressCardProps) => {
  const theme = useTheme();

  const IconComponent =
    props.type === BeneficiaryType.SUBSCRIBER
      ? PersonCircleIcon
      : props.type === BeneficiaryType.PARTNER
        ? PersonDuoCircleIcon
        : PersonTrioCircleIcon;

  const label: string =
    props.type === BeneficiaryType.SUBSCRIBER
      ? 'Votre client(e)'
      : props.type === BeneficiaryType.PARTNER
        ? 'Son conjoint'
        : 'Ses enfants';

  return (
    <FormCard
      icon={p => (
        <IconComponent
          {...p}
          bgColor={theme.palette.text.secondary}
          color={theme.palette.background.default}
        />
      )}
      label={label}
      count={props.count}
      selected={props.selected}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </FormCard>
  );
};

export default FormBeneficiaryExpressCard;
