import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { IconProps } from 'components/Icon/settings';

export type FormCardProps = {
  icon: (props: IconProps) => JSX.Element;
  label: string;
  count?: number;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (_: boolean) => void;
  children: JSX.Element;
};

const FormCard = (props: FormCardProps) => {
  const theme = useTheme();
  const IconComponent = props.icon;

  const label: string = props.label;

  const cursor =
    props.onClick === undefined || props.selected || props.disabled
      ? 'default'
      : 'pointer';

  return (
    <Card
      sx={{
        cursor,

        borderRadius: 6,
        p: {
          lg: 3,
          xs: 2,
        },
        opacity: props.disabled ? 0.5 : 1,
        transition: 'transform 150ms ease-in-out',
        ':hover': {
          transform:
            props.onClick === undefined || props.selected
              ? 'none'
              : 'translateY(-6px)',
        },
      }}
      onClick={() => {
        if (props.selected || props.onClick === undefined || props.disabled)
          return;
        props.onClick(true);
      }}
    >
      <Grid
        container
        spacing={{
          sm: 4,
          xs: props.selected ? 2 : 0,
        }}
      >
        <Grid
          item
          sm="auto"
          xs={12}
          sx={{
            minWidth: {
              sm: 240,
              xs: 'auto',
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => {
              if (props.onClick === undefined || props.disabled) return;
              props.onClick(!props.selected);
            }}
            sx={{ cursor }}
          >
            {(props.onClick || props.selected !== undefined) && (
              <Checkbox disabled={props.disabled} checked={props.selected} />
            )}
            <IconComponent
              size="large"
              bgColor={theme.palette.background.default}
              color={theme.palette.text.primary}
            />
            <Typography component="span">{label}</Typography>
            {props.selected && props.count !== undefined && (
              <Chip label={props!.count.toString()} />
            )}
          </Stack>
        </Grid>

        <Grid
          item
          xs
          sx={{
            transition:
              'max-height 150ms ease-in-out, visibility 150ms ease-in-out',
            maxHeight: props.selected === false ? '0px' : '2000px',
            visibility: props.selected === false ? 'hidden' : 'visible',
          }}
        >
          {props.children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default FormCard;
