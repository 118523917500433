import createTheme, { Theme } from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { frFR } from '@mui/material/locale';
import {
  alpha,
  darken,
  Palette,
  PaletteColorOptions,
  PaletteOptions,
} from '@mui/material/styles';

import { opacityLight, paletteLight } from './light/palette';
import { paletteDark } from './dark/palette';
import { opacityBlue, paletteBlue } from './blue/palette';
import { CheckBoxIcon } from '../../components/Icon/CheckBoxIcon';
import { CheckBoxOutlinedBlankIcon } from '../../components/Icon/CheckBoxOutlinedBlankIcon';

// Add palette color.
declare module '@mui/material/styles' {
  interface CustomPalette {
    default?: PaletteColorOptions;
    dark?: PaletteColorOptions;
  }

  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

// Add Button variant.
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true;
    dark: true;
  }
  interface ButtonPropsVariantOverrides {
    gradient: true;
  }
}

export type themesCode = 'blue' | 'light' | 'dark';

/**
 * Get MUI Theme.
 * @param  {'blue' | 'light' | 'dark'} mode
 * @return {Theme}
 */
export const getTheme = (mode: string): Theme => {
  let palette: PaletteOptions = paletteBlue;
  let opacity = opacityBlue;

  if (mode === 'dark') palette = paletteDark;
  else if (mode === 'light') {
    palette = paletteLight;
    opacity = opacityLight;
  }

  const themePalette: Palette = buildThemePalette(palette).palette;

  return buildTheme(themePalette, opacity);
};

/**
 * Build MUI Palette Theme.
 * @param  {PaletteOptions} palette
 * @return {Theme}
 */
export const buildThemePalette = (palette: PaletteOptions): Theme => {
  const themeBase = createTheme({
    palette: {
      ...palette,
      tonalOffset: 0.1,
    },
  });

  return createTheme(themeBase, {
    palette: {
      default: themeBase.palette.augmentColor({
        color: {
          main: themeBase.palette.text.primary,
          contrastText: themeBase.palette.background.default,
        },
      }),
      dark: themeBase.palette.augmentColor({
        color: {
          main: themeBase.palette.background.default,
          contrastText: themeBase.palette.text.primary,
        },
      }),
    },
  });
};

/**
 * Get the color from the label (example : 'primary').
 * @param  {string} color : Color code or label
 * @param  {Theme} theme : Theme MUI
 * @return {string}
 */
export function getPaletteColor(color: string, theme: Theme): string {
  const paletteKeys = Object.keys(theme.palette) as string[];

  for (const key of paletteKeys) {
    const paletteColor = (theme.palette as any)[key];

    if (
      paletteColor &&
      typeof paletteColor === 'object' &&
      'main' in paletteColor
    ) {
      if (key === color) {
        return (paletteColor as { main: string }).main;
      }
    }
  }

  return color;
}

/**
 * Build MUI Theme.
 * @param  {palette} palette - (optional)
 * @return {Theme}
 */
export const buildTheme = (palette?: Palette, opacity?: any): Theme => {
  if (!palette) palette = createTheme().palette;
  return responsiveFontSizes(
    createTheme(
      {
        palette: palette,
        typography: {
          fontFamily: ['Quicksand'].join(','),
          fontWeightRegular: 500,
          fontWeightBold: 700,
          h1: {
            fontSize: '1.75rem',
            lineHeight: 1.5,
            fontWeight: 'bold',
          },
          h2: {
            fontSize: '1.5rem',
            lineHeight: 1.5,
            fontWeight: 'bold',
          },
          h3: {
            fontSize: '1.25rem',
            lineHeight: 1.25,
            fontWeight: 'bold',
          },
          h5: {
            fontSize: '1.125rem',
            lineHeight: 1.375,
            fontWeight: 'bold',
          },
          body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 500,
          },
          body2: {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 'normal',
            color: palette.text.secondary,
          },
          caption: {
            fontSize: '0.8rem',
            lineHeight: 1.5,
            fontWeight: 'normal',
            color: palette.text.secondary,
          },
          button: {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
        },
        components: {
          MuiAlert: {
            styleOverrides: {
              standardSuccess: {
                backgroundColor: palette.success.main,
                color: palette.success.contrastText,
              },
              standardError: {
                backgroundColor: palette.error.main,
                color: palette.error.contrastText,
              },
              standardWarning: {
                backgroundColor: palette.warning.main,
                color: palette.warning.contrastText,
              },
              standardInfo: {
                backgroundColor: palette.info.main,
                color: palette.info.contrastText,
              },
              root: {
                width: '100%',
                borderRadius: 16,
                textAlign: 'left',
                color: palette.text.primary,
                backgroundColor: palette.background.default,
              },
              action: {
                padding: 0,
                alignItems: 'center',
              },
              message: {
                paddingTop: '10px',
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                '&:first-of-type': {
                  borderRadius: 16,
                },
                '&:last-of-type': {
                  borderRadius: 16,
                },
              },
            },
          },
          MuiAccordionSummary: {
            styleOverrides: {
              root: {
                minHeight: 0,
                '&.Mui-expanded': {
                  minHeight: 0,
                },
              },
              content: {
                margin: 0,
                '&.Mui-expanded': {
                  margin: 0,
                },
              },
            },
          },
          MuiAutocomplete: {
            styleOverrides: {
              input: {
                paddingTop: 14,
                paddingBottom: 14,
                paddingRight: 16,
                paddingLeft: 20,
              },
            },
          },
          MuiButton: {
            defaultProps: {
              variant: 'contained',
            },
            styleOverrides: {
              root: {
                paddingTop: 8,
                paddingBottom: 8,
                paddingRight: 12,
                paddingLeft: 12,
                textTransform: 'none',
                borderRadius: 32,
              },
            },
            variants: [
              {
                props: { size: 'small' },
                style: {
                  fontSize: '0.9rem',
                },
              },
              {
                props: { size: 'large' },
                style: {
                  paddingRight: 16,
                  paddingLeft: 16,
                  fontSize: '1.1rem',
                },
              },
              {
                props: { variant: 'outlined' },
                style: {
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingRight: 12,
                  paddingLeft: 12,
                  border: '2px solid',
                  ':hover': {
                    border: '2px solid',
                  },
                  '&.Mui-disabled': {
                    border: '2px solid',
                  },
                },
              },
              {
                props: { variant: 'contained' },
                style: {
                  boxShadow: 'none',
                  ':hover': {
                    boxShadow: 'none',
                  },
                  ':active': {
                    boxShadow: 'none',
                  },
                  ':focus': {
                    boxShadow: 'none',
                  },
                },
              },
              {
                props: { variant: 'gradient' },
                style: {
                  color: 'white',
                  background: `linear-gradient(89.32deg, ${palette.info.main} 23.36%, #10D9D3 113.4%)`,
                  '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.26) !important',
                    background: 'rgba(0, 0, 0, 0.12) !important',
                  },
                  '&.MuiButton-colorSecondary': {
                    color: 'white',
                    background: `linear-gradient(89.32deg, ${palette.primary.main} 13.81%, #2155CD 99.49%)`,
                  },
                },
              },
            ],
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 16,
                padding: 4,
                boxShadow: 'none',
              },
            },
            variants: [
              {
                props: {
                  variant: 'outlined',
                },
                style: {
                  border: `2px solid ${palette.divider}`,
                },
              },
            ],
          },
          MuiCheckbox: {
            defaultProps: {
              icon: <CheckBoxOutlinedBlankIcon />,
              checkedIcon: <CheckBoxIcon />,
            },
            styleOverrides: {
              root: {},
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                '&.MuiChip-contained.MuiChip-colorDefault': {
                  backgroundColor: alpha('#000000', opacity.chip || 0.25),
                },
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              html: {
                pre: {
                  paddingRight: 4,
                  paddingLeft: 4,
                  margin: 0,
                  borderRadius: 4,
                  backgroundColor: alpha('#000000', opacity.pre || 0.2),
                },
                '*::-webkit-scrollbar': {
                  height: 12,
                  width: 10,
                },
                '*::-webkit-scrollbar-thumb': {
                  borderRadius: 4,
                  backgroundColor: palette.divider,
                  border: `1px solid ${palette.divider}`,
                },
                '*::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: alpha(
                    palette.divider,
                    opacity.scrollbarThumb || 0.8,
                  ),
                },
                '*::-webkit-scrollbar-thumb:active': {
                  backgroundColor: palette.divider,
                },
              },
            },
          },
          MuiDateCalendar: {
            styleOverrides: {
              root: {
                borderRadius: 16,
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: 16,
                backgroundColor: palette.background.default,
              },
            },
          },
          MuiDialogContentText: {
            styleOverrides: {
              root: {
                color: palette.text.primary,
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                border: 1,
              },
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                marginLeft: 16,
              },
            },
          },
          MuiFilledInput: {
            styleOverrides: {
              root: {
                boxSizing: 'border-box',
                borderRadius: 32,
                background: alpha('#000000', opacity.field || 0.15),
                '&:hover': {
                  background: alpha('#000000', opacity.hover || 0.2),
                },
                '&.Mui-focused': {
                  background: alpha('#000000', opacity.focused || 0.15),
                },
                '&.Mui-disabled': {
                  color: palette.action.disabled,
                  background: alpha(
                    palette.action.disabledBackground,
                    opacity.disabled || 0.2,
                  ),
                },
              },
              input: {
                paddingBottom: 6,
                paddingLeft: 16,
                paddingTop: 21,
              },
            },
            variants: [
              {
                props: { size: 'small' },
                style: {
                  input: {
                    paddingTop: 16,
                  },
                },
              },
            ],
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: palette.text.primary,
                '&.Mui-disabled': {
                  color: palette.action.disabled,
                },
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              root: {
                borderRadius: 8,
                padding: '0px 12px',
                border: `1px solid #00000000`,
                '&.Mui-focused': {
                  borderColor: palette.primary.main,
                },
                '&.Mui-disabled': {
                  color: palette.action.disabled,
                  backgroundColor: alpha(
                    palette.action.disabledBackground,
                    0.2,
                  ),
                },
              },
            },
            variants: [
              {
                props: { size: 'small' },
                style: {
                  input: {
                    padding: '6px 0px',
                  },
                },
              },
              {
                props: { size: 'medium' },
                style: {
                  input: {
                    padding: '8px 0px',
                  },
                },
              },
            ],
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                '&:focus': {
                  backgroundColor: '#00000000',
                },
              },
              icon: {
                top: 'calc(50% - 10px)',
                right: '12px',
                transform: 'rotate(0deg)',
                transition: 'all 150ms ease-in-out',
              },
              iconOpen: {
                top: 'calc(50% - 10px)',
                transform: 'rotate(180deg)',
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                background: alpha('#000000', opacity.field || 0.15),
                borderRadius: 4,
                '&:hover': {
                  background: alpha('#000000', opacity.hover || 0.2),
                },
                '&.Mui-focused': {
                  backgroundColor: alpha('#000000', opacity.focused || 0.15),
                },
                '&.Mui-disabled': {
                  color: palette.action.disabled,
                  background: alpha(
                    palette.action.disabledBackground,
                    opacity.disabled || 0.2,
                  ),
                },
                'input:autofill': {
                  borderRadius: 32,
                  transition: 'background-color 500000s 0s', // The navigator overrite the background-color and there is no way to disable it, so we just make the color an eternity to change.
                  boxShadow: `0 0 0 50px ${alpha(palette.primary.main, opacity.focused || 0.2)} inset`,
                  textFillColor: palette.text.primary,
                },
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {},
            },
            variants: [
              {
                props: { variant: 'filled' },
                style: {
                  transform: 'translate(16px, 14px) scale(1)',
                  '&.Mui-focused': {
                    transform: 'translate(16px, 6px) scale(0.75)',
                  },
                  '&.MuiFormLabel-filled': {
                    transform: 'translate(16px, 6px) scale(0.75)',
                  },
                },
              },
              {
                props: { variant: 'filled', size: 'small' },
                style: {
                  transform: 'translate(16px, 10px) scale(1)',
                  '&.Mui-focused': {
                    transform: 'translate(16px, 3px) scale(0.75)',
                  },
                  '&.MuiFormLabel-filled': {
                    transform: 'translate(16px, 3px) scale(0.75)',
                  },
                },
              },
              {
                props: { variant: 'outlined' },
                style: {
                  transform: 'translate(14px, 12px) scale(1)',
                  '&.Mui-focused': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                  },
                  '&.MuiFormLabel-filled': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                  },
                },
              },
              {
                props: { variant: 'outlined', size: 'small' },
                style: {
                  transform: 'translate(14px, 8px) scale(1)',
                  '&.Mui-focused': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                  },
                  '&.MuiFormLabel-filled': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                  },
                },
              },
              {
                props: { variant: 'standard' },
                style: {
                  transform: 'translate(12px, 25px) scale(1)',
                  '&.Mui-focused': {
                    transform: 'translate(0px, 0px) scale(0.75)',
                  },
                  '&.MuiFormLabel-filled': {
                    transform: 'translate(0px, 0px) scale(0.75)',
                  },
                },
              },
              {
                props: { variant: 'standard', size: 'small' },
                style: {
                  transform: 'translate(12px, 20px) scale(1)',
                  '&.Mui-focused': {
                    transform: 'translate(0px, 0px) scale(0.75)',
                  },
                  '&.MuiFormLabel-filled': {
                    transform: 'translate(0px, 0px) scale(0.75)',
                  },
                },
              },
            ],
          },
          MuiLink: {
            styleOverrides: {
              root: {
                fontFamily: 'Quicksand',
                textDecoration: 'none',
                ':hover': {
                  cursor: 'pointer',
                },
              },
            },
          },
          MuiLinearProgress: {
            styleOverrides: {
              root: {
                borderRadius: 8,
                background: palette.background.default,
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                background: 'none',
                borderRadius: 32,
                '&:hover': {
                  background: 'none',
                },
                '&.Mui-focused': {
                  background: 'none',
                },
                '&.Mui-disabled': {
                  color: palette.action.disabled,
                  background: alpha(
                    palette.action.disabledBackground,
                    opacity.disabled || 0.2,
                  ),
                },
              },
            },
            variants: [
              {
                props: { size: 'small' },
                style: {
                  input: {
                    padding: '8px 16px',
                  },
                },
              },
              {
                props: { size: 'medium' },
                style: {
                  input: {
                    padding: '12px 16px',
                  },
                },
              },
            ],
          },
          MuiPopover: {
            styleOverrides: {
              paper: {
                marginTop: 8,
                backgroundColor: palette.background.default,
                borderRadius: '16px',
                maxWidth: '300px',
              },
            },
          },
          MuiSnackbar: {
            styleOverrides: {
              root: {
                maxWidth: '1200px',
              },
            },
          },
          MuiSkeleton: {
            styleOverrides: {
              root: {
                borderRadius: 16,
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                alignItems: 'flex-start',
                minWidth: '10px',
                textTransform: 'none',
              },
            },
          },
          MuiTableSortLabel: {
            styleOverrides: {
              root: {
                color: palette.text.secondary,
                '&.Mui-active': {
                  color: palette.text.secondary,
                },
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                padding: 8,
                borderBottom: 0,
              },
              head: {
                padding: 16,
                borderBottom: '1px solid #6B7793',
                color: palette.text.disabled,
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              variant: 'filled',
            },
          },
          MuiToggleButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                padding: '4px 12px',
                borderRadius: 12,
                color: palette.text.primary,
                fontWeight: 500,
                '&.Mui-selected': {
                  fontWeight: 700,
                },
              },
            },
            variants: [
              {
                props: { size: 'small' },
                style: {
                  fontSize: '0.9rem',
                },
              },
              {
                props: { size: 'large' },
                style: {
                  paddingRight: 16,
                  paddingLeft: 16,
                  fontSize: '1.1rem',
                },
              },
            ],
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                borderRadius: 8,
                color: palette.text.primary,
                background: darken(
                  palette.background.default,
                  opacity.tooltip || 0.2,
                ),
                fontSize: '0.75rem',
              },
            },
          },
          MuiPickersPopper: {
            styleOverrides: {
              paper: {
                marginTop: 8,
                backgroundColor: palette.background.default,
                borderRadius: 16,
              },
            },
          },
        },
      },
      frFR,
    ),
  );
};
