import React, { createContext, useContext } from 'react';
import { Project } from 'models/oav/Project.models';

export type ProjectContextData = {
  project?: Project;
};

export type FormulasSelectionMap = { [key: string]: string[] };

export type ProjectContextProps = {
  selectedFormulas: FormulasSelectionMap;
  setSelectedFormulas: (formulasMap: FormulasSelectionMap) => void;
  resetSelectedFormulas: () => void;
  commercialPropositionDialogOpen: boolean;
  closeCommercialPropositionDialog: () => void;
  openCommercialPropositionDialog: () => void;
  estimationDialogOpen: boolean;
  closeEstimationDialog: () => void;
  openEstimationDialog: () => void;
  dataPersisted: ProjectContextData; // TODO : DELETEME
  dataCurrent: ProjectContextData;
  setDataPersisted: React.Dispatch<React.SetStateAction<ProjectContextData>>; // TODO : DELETEME
  setDataCurrent: React.Dispatch<React.SetStateAction<ProjectContextData>>;
};

export const ProjectContext = createContext<ProjectContextProps | undefined>(
  undefined,
);

export const useProjectContext = (): ProjectContextProps => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within an ProjectProvider');
  }
  return context;
};
