import React, { PropsWithChildren } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

export type TypographyStatusProps = {
  statusColor: string;
  borderLeftOnly?: boolean;
} & TypographyProps;

export const TypographyStatus: React.FC<
  PropsWithChildren<TypographyStatusProps>
> = ({ statusColor, children, borderLeftOnly, ...props }) => {
  const customStyle = borderLeftOnly
    ? {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        '&::before': {
          content: '""',
          display: 'inline-block',
          height: '2rem',
          width: '2px',
          background: statusColor,
          margin: '5px 0',
        },
        ...props.sx,
      }
    : {
        border: `1px solid ${statusColor}`,
        borderRadius: '1rem',
        padding: '0.25rem 1rem',
        display: 'inline-flex',
        gap: '10px',
        alignItems: 'center',
        '&::before': {
          content: '""',
          display: 'inline-block',
          width: '10px',
          height: '10px',
          borderRadius: '5px',
          background: statusColor,
        },
        ...props.sx,
      };
  return (
    <Typography {...props} sx={customStyle}>
      {children}
    </Typography>
  );
};
